import { INTEGRATION_CODE_SHOPIFY } from '../../../../common/constants';
import { ShopifyLogo, ShopifySmallLogo } from './ShopifyLogo';
import ConnectForm from './ConnectForm/ConnectForm';
import ShopifySidebarElement from './SidebarElement';

export default {
  code: INTEGRATION_CODE_SHOPIFY,
  logo: ShopifyLogo,
  small_logo: ShopifySmallLogo,
  connect_form: ConnectForm,
  sidebar_element: ShopifySidebarElement,
};
