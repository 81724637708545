import Stripe from 'stripe';
import {Action as ReduxAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import express from 'express';
import {TFunction} from 'i18next';
import {I18next} from 'i18next-http-middleware';
import * as core from 'express-serve-static-core';
import {BinaryLike} from 'crypto';
import {ReactElement} from 'react';

export interface ISendMessageResult {
  external_id: string;
  order: number;
  text: string;
  attachments: Quickley.MessageAttachment[];
}

export interface IDirectMessageParseResult {
  external_id: string;
  datetime: number;
  order: number;
  text: string;
  uniq_key: string;
  attachments: Quickley.MessageAttachment[];
  person_contact: Quickley.PersonContact;
  meta: Quickley.MessageMeta;
}

export interface ICommentParseResult extends IDirectMessageParseResult {
  entity: Quickley.ExternalEntity;
  parent_comment_id?: string;
}

export interface ILogger {
  error(err: string): void;
  info(str: string): void;
}

declare global {
  interface Window {
    __stage__: string;
    __release: string;
    yaCounter55252984: any;
    dataLayer: any;
  }
}

export interface IStore<S> {
  dispatch: ThunkDispatch<S, void, ReduxAction>;
  getState: () => S;
}

export interface IEssentialRootState {
  module_name: string;
  profile: Quickley.Profile;
  team: Quickley.Team;
  navigation: {};
  integrations: {
    items: Quickley.Integration[];
  };
}
export interface IMainRootState extends IEssentialRootState {
  accounts: {
    items: Quickley.Account[];
  };
  tags: {
    items: Quickley.Tag[];
  };
  notifications: [];
}

export interface IThreadState extends Quickley.Thread {
  thread_id: string;
  is_fetching: boolean;
  has_more: boolean;
  error_message: string;
}

export interface IInboxRootState extends IMainRootState {
  inbox: {
    items: Quickley.Thread;
  };
  thread: IThreadState;
  person_threads: {
    person_id: string;
    items: Quickley.Thread[];
    is_loading: boolean;
  };
}

export interface ISettingsRootState extends IEssentialRootState {
  accounts: {
    items: Quickley.Account[];
  };
  saved_replies: {
    items: Quickley.SavedReply[];
  };
}

export interface ICommentsStateItem {
  is_expanded: boolean;
  thread: Quickley.Thread;
  inactive?: boolean;
}

export interface ICommentsState {
  is_loading: boolean;
  account_id?: string;
  last_loaded_cursor?: string;
  quick_reply_message_id?: string;
  items: ICommentsStateItem[];
}

export interface ICommentsRootState extends IMainRootState {
  comments: ICommentsState;
  thread: IThreadState;
}

export interface IOnboardingRootState extends IEssentialRootState {
  onboarding: {};
}

export interface IAccountCandidate {
  id?: string;
  external_id: string;
  name: string;
  pic: string;
  access_token?: string;
  application?: Quickley.Application;
  already_added?: boolean;
  added_to_another_profile?: boolean;
  ig_followers_restriction?: boolean;
  ig_messenger_unavailable?: boolean;
}

export interface IAccountsRootState extends IEssentialRootState {
  application: Quickley.Application;
  user: Quickley.Person;
  accounts: IAccountCandidate[];
}

export interface IFCMToken {
  profile_id: string;
  token: string;
  user_agent: string;
}

export interface ISessionProfileObject {
  id: string;
  email: string;
  status: Quickley.PersonStatus;
  username: string;
  team_id: string;
  role: unknown;
  person_id: string;
  verified: boolean;
  time_zone: number;
  onboarding_state: Quickley.OnboardingState;
  accounts: Quickley.Account[];
  partner_id: string;
}

export interface ISession {
  profile?: ISessionProfileObject;
  fcm_token?: string;
  secret?: string;
}

export interface IRequest<
  P = core.ParamsDictionary,
  ResBody = any,
  ReqBody = any,
  ReqQuery = core.Query,
  Locals extends Record<string, any> = Record<string, any>
> extends express.Request<P, ResBody, ReqBody, ReqQuery, Locals> {
  session: ISession;
  commitSession: (session_data: ISession) => Promise<void>;
  profile: Quickley.Profile;
  getTeamId(): string;
  i18n: I18next;
  t: TFunction;
  isAuthenticated: () => boolean;
  rawBody: BinaryLike;
}

export interface IQChatLandingButton {
  application: Quickley.Application;
  link: string;
}

export interface IQChatLanding {
  title: string;
  subtitle: string;
  buttons: IQChatLandingButton[];
}

export type TQChatButtonType = 'horizontal' | 'hexagon';

export type TQChatButtonPosition = 'left' | 'center' | 'right';

export interface IPromoCode {
  name: string;
  code: string;
}

export interface IAmoCRMScope {
  profile_id: string;
  scope_id: string;
}

export interface IPipeline {
  id: number;
  graph: [Record<string, Record<string, any>>, Record<string, string[]>];
}

export interface IPipelineRootState {
  pipeline: IPipeline;
}

export interface IPlatform {
  type: Quickley.Application;
  name: Quickley.PlatformName;
  full_name: string;
  logo: {
    small: ReactElement;
    big: ReactElement;
  };
  allow_message_edit?: boolean;
  getPublicLink?(account: Quickley.Account): string;
}

export namespace Quickley {
  export enum PersonType {
    USER = 1,
    GROUP,
    OPERATOR
  }

  export enum PersonContactType {
    ig = 'ig',
    ig_comment = 'ig_comment',
    vk = 'vk',
    fb = 'fb',
    tg = 'tg',
    viber = 'viber',
    qchat = 'qchat',
    email = 'email',
    phone = 'phone'
  }

  export interface PersonContact {
    id?: string;
    name?: string;
    pic?: string;
    type: Quickley.PersonContactType;
    value: string;
    meta?: Record<string, any>;
  }

  export enum Application {
    Instagram = 1,
    VK,
    Facebook,
    Telegram,
    Viber,
    QuickleyChat,
    Email,
    WhatsApp,
    Info = 100
  }

  export enum PlatformName {
    Instagram = 'ig',
    VK = 'vk',
    Facebook = 'fb',
    Telegram = 'tg',
    Viber = 'viber',
    QuickleyChat = 'qchat',
    Email = 'email',
    WhatsApp = 'wa',
    Info = 'info'
  }

  export enum MessageType {
    Post = 100,
    Direct = 200,
    Action = 300
  }

  export enum PersonStatus {
    Offline,
    Online,
    InChat
  }

  export enum ExternalEntityType {
    Post = 100
  }

  export enum AttachmentType {
    Unknown,
    File,
    Photo,
    Video,
    Audio,
    Location,
    Animation,
    Contact,
    Sticker,
    LinkPreview,
    Markdown,
    Product,
    StoryMention
  }

  export interface Attachment<T> {
    id?: string;
    type: T;
    external_id: string;
    meta: Record<string, unknown>;
    account_id?: string;
  }

  export type AttachmentUnknown = Quickley.Attachment<Quickley.AttachmentType.Unknown>;

  interface AttachmentPreviewMeta {
    preview?: string;
    preview_size?: {
      width: number;
      height: number;
    };
    preview_mime_type?: string;
  }

  export interface IAttachmentFile<T, M = {}> extends Quickley.Attachment<T> {
    meta: {
      url: string;
      file_name?: string;
      file_size?: number;
      mime_type?: string;
      title?: string;
    } & M;
  }

  export type AttachmentFile = Quickley.IAttachmentFile<
    Quickley.AttachmentType.File,
    AttachmentPreviewMeta
  >;
  export type AttachmentPhoto = Quickley.IAttachmentFile<
    Quickley.AttachmentType.Photo,
    AttachmentPreviewMeta
  >;
  export type AttachmentVideo = Quickley.IAttachmentFile<
    Quickley.AttachmentType.Video,
    AttachmentPreviewMeta
  >;
  export type AttachmentAudio = Quickley.IAttachmentFile<Quickley.AttachmentType.Audio>;
  export type AttachmentAnimation = Quickley.IAttachmentFile<Quickley.AttachmentType.Animation>;

  export interface AttachmentLocation
    extends Quickley.Attachment<Quickley.AttachmentType.Location> {
    meta: {
      latitude: number;
      longitude: number;
    };
  }

  export interface AttachmentContact extends Quickley.Attachment<Quickley.AttachmentType.Contact> {
    meta: {
      phone_number?: string;
      vcard?: string;
      title: string;
    };
  }

  export interface AttachmentStickerMeta {
    tg_animated?: boolean;
    emoji?: string;
    file_size?: number;
    content_encoding?: string;
  }
  export type AttachmentSticker = Quickley.IAttachmentFile<
    Quickley.AttachmentType.Sticker,
    AttachmentStickerMeta
  >;

  export interface AttachmentLink extends Quickley.Attachment<Quickley.AttachmentType.LinkPreview> {
    meta: {
      url: string;
      title: string;
      description?: string;
      preview?: string;
      image?: string;
    };
  }

  export interface AttachmentMarkdown
    extends Quickley.Attachment<Quickley.AttachmentType.Markdown> {
    meta: {
      text: string;
    };
  }

  export interface AttachmentProduct extends Quickley.Attachment<Quickley.AttachmentType.Product> {
    meta: {
      title?: string;
      subtitle?: string;
      preview?: string;
      url?: string;
    };
  }

  export type AttachmentStoryType = 'IMAGE' | 'VIDEO';
  export interface AttachmentStoryMention
    extends Quickley.Attachment<Quickley.AttachmentType.StoryMention> {
    meta: {
      type: AttachmentStoryType;
      url: string;
    };
  }

  export type MessageAttachment =
    | Quickley.AttachmentUnknown
    | Quickley.AttachmentFile
    | Quickley.AttachmentPhoto
    | Quickley.AttachmentVideo
    | Quickley.AttachmentAudio
    | Quickley.AttachmentAnimation
    | Quickley.AttachmentLocation
    | Quickley.AttachmentContact
    | Quickley.AttachmentSticker
    | Quickley.AttachmentLink
    | Quickley.AttachmentMarkdown
    | Quickley.AttachmentProduct
    | Quickley.AttachmentStoryMention;

  export interface PersonMeta {
    name: string;
    email: string;
    phone: string;
    preform_sent: boolean;
    amojo_id: string;
  }

  export interface Person {
    id: string;
    name: string;
    pic: string;
    type: Quickley.PersonType;
    last_inbox_activity: number;
    time_created: number;
    meta: Partial<Quickley.PersonMeta>;
    contacts: Quickley.PersonContact[];
  }

  export interface AccountMeta {
    username: string;
    chat_id: string;
    title: string;
    subtitle: string;
    greeting: string;
    displayed_managers: unknown;
    quickley_branding: unknown;
    attachments: unknown;
    typing_insight: boolean;
    show_on_mobile: boolean;
    multi_dialogs: unknown;
    preform: unknown;
    landing: unknown;
    color: string;
    text_color: string;
    address: string;
    email: string;
    description: string;
    websites: string[];
  }

  export interface Account {
    id: string;
    application: Quickley.Application;
    external_id: string;
    name: string;
    display_name: string;
    pic: string;
    person_id: string;
    error: string;
    meta: Quickley.AccountMeta;
    token?: string;
  }

  export interface Integration {
    id?: string;
    coming_soon?: boolean;
    available_on_freemium?: boolean;
    approved?: boolean;
    code: string; // TODO check
    status?: boolean;
    error: string;
    meta: Record<string, any>;
  }

  export enum ProductName {
    Inbox = 'inbox',
    ActiveContacts = 'active_contacts',
    Seats = 'seats',
    Waba = 'waba',
    WhatsappConversationsUserInitiated = 'whatsapp_conversations_user_initiated',
    WhatsappConversationsBusinessInitiated = 'whatsapp_conversations_business_initiated',
    WhatsappConversationsMarketing = 'whatsapp_conversations_marketing',
    WhatsappConversationsUtility = 'whatsapp_conversations_utility',
    WhatsappConversationsAuthentication = 'whatsapp_conversations_authentication',
    WhatsappConversationsService = 'whatsapp_conversations_service',
  }

  export interface SubscriptionProduct {
    quantity: number;
    usage: number;
  }

  export interface Subscription {
    name: string;
    is_freemium?: boolean;
    is_trial?: boolean;
    trial_end?: number;
    cancel_at?: number;
    products: {
      [key in Quickley.ProductName]?: Quickley.SubscriptionProduct;
    };
  }

  export type Currency = 'usd' | 'rub';

  export interface Manager {
    id: string;
    email: string;
    team_id: string;
    datetime: number;
    last_activity: number;
    username: string;
    status: Quickley.PersonStatus;
    role: unknown;
    invite_datetime: number;
    invite_active: number;
    person: Quickley.Person;
    accounts: Quickley.Account[];
  }

  export enum OnboardingState {
    complete = 0,
    add_channels = 1,
    syncing = 2,
    manager_user_info = 11,
    owner_user_info = 21
  }

  export interface Profile extends Quickley.Manager {
    onboarding_state: Quickley.OnboardingState;
    intro_completed: boolean;
    team_id: string;
  }

  export interface ProfileConnectInfo {
    email: string;
    workspace_display_name: string;
    person_name: string;
    lng: string;
  }

  export interface MessageMetaReplyToMessage {
    type: 'message';
    data: {
      message: string;
    };
  }

  export interface MessageMetaReplyToStory {
    type: 'story';
    data: {
      id: string;
      thumbnail_url?: string;
      media_type: 'IMAGE' | 'VIDEO';
      media_url: string;
      owner: {
        id: string;
        username: string;
        profile_picture_url: string;
      };
      timestamp: string;
    };
  }

  export type MessageMetaReplyTo =
    | Quickley.MessageMetaReplyToMessage
    | Quickley.MessageMetaReplyToStory;

  export interface MessageMetaReactions {
    reacted_by_myself?: boolean;
    reacted_by_client?: boolean;
  }

  export interface MessageMeta extends Record<string, unknown> {
    reply_to?: Quickley.MessageMetaReplyTo;
    reactions?: Quickley.MessageMetaReactions;
  }

  export interface Message {
    id: string;
    type: Quickley.MessageType;
    external_id: string;
    datetime: number;
    text: string;
    cursor: string;
    author: Quickley.Person;
    attachments: Quickley.MessageAttachment[];
    meta: Quickley.MessageMeta;
    deleted: boolean;
    error: string;
  }

  export interface Note {
    id: string;
    text: string;
  }

  export interface Tag {
    id: number;
    name: string;
    color: string;
  }

  export interface ExternalEntityMeta {
    text: string;
    preview_url?: string;
    preview_url_big?: string;
  }

  export interface ExternalEntity {
    id?: string;
    external_id?: string;
    type: Quickley.ExternalEntityType;
    meta: Quickley.ExternalEntityMeta;
    info?: {
      counters?: {};
    };
  }

  export interface ThreadMeta {
    synced: boolean;
    thread_id: string;
    unavailable: boolean;
  }

  export interface Thread {
    id: string;
    account_id: string;
    items: Quickley.Message[];
    total_count: number;
    tags: number[];
    person: Quickley.Person;
    person_contact_id: string;
    entity: Quickley.ExternalEntity;
    note: Quickley.Note;
    finalized: boolean;
    last_cursor: string;
    last_read_cursor: string;
    client_last_read_cursor: string;
    meta: Quickley.ThreadMeta;
    manager_id?: string;

    /**
     * @deprecated
     * Use thread.id instead
     */
    thread_id: string;
  }

  export interface Team {
    id: string;
    stripe_customer_id: string;
    subscription: Quickley.Subscription;
    currency: Quickley.Currency;
    trial_used: boolean;
    default_payment_method: Stripe.PaymentMethod;
    balance: number;
    seat_available: boolean;
    is_company: boolean;
    whats_app_connect_status: boolean;
    workspace_display_name: string;
    workspace: string;
    managers: Quickley.Manager[];
  }

  export interface SavedReply {
    id: string;
    shortcut: string;
    text: string;
  }

  export enum PipelineMemberPolicy {
    // В пайплайн можно зайти всего один раз
    once = 'once',
    // В пайплайн можно зайти сколько угодно раз, но одна персона может быть только в одном экземпляре
    unique = 'unique',
    // В пайплайн можно зайти сколько угодно раз без каких либо ограничений
    unlimited = 'unlimited'
  }

  export interface Pipeline {
    id: string;
    graph: [WithId[], Record<string, string[]>];
    policy: PipelineMemberPolicy;
    active: boolean;
  }
}

export interface WithId {
  id: string;
}
