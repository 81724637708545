import { FORM_ERROR } from 'final-form';
import { emailValidate, passwordLengthValidate } from '../../common/validations';

// В файле все хелперы для работы с final-form

// 1. Mutators — https://final-form.org/docs/final-form/types/Mutator

/**
 * Очистить значение поля в форме
 */
export const clear = ([name], state, { changeValue }) => changeValue(state, name, () => undefined);

// 2. Validators

export function compose_validators(...validators) {
  return function (value, all_values, meta) {
    return validators.reduce(
      (result, validator) => result || validator(value, all_values, meta),
      undefined,
    );
  };
}

export function max_len(t, len) {
  return function (value, all_values, meta) {
    if (!meta.pristine) {
      if (value && value.length > len) {
        return t('validation.max_length_exceeded');
      }
    }
  };
}

export function required(t) {
  return function (value) {
    if (!value) {
      return t('validation.required');
    }
  };
}

/**
 * Валидация адреса электронной почты
 */
export function email(t) {
  return function (value) {
    if (!emailValidate(value)) {
      return t('validation.invalid_email');
    }
  };
}

export function password(t) {
  return function (value) {
    if (value && !passwordLengthValidate(value)) {
      return t('validation.invalid_password_len');
    }
  };
}

export function url(t) {
  const reg_exp =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return function (value) {
    if (!reg_exp.test(value)) {
      return t('validation.invalid_url');
    }
  };
}

// 3. Errors catching

export function catchFormError(e) {
  if (e.details && (e.details.message || e.details.errors)) {
    return {
      [FORM_ERROR]: e.details.message,
      ...(e.details.errors || {}),
    };
  } else {
    return { [FORM_ERROR]: (e.details && e.details.message) || e.message };
  }
}
