import { INTEGRATION_CODE_ECWID } from '../../../../common/constants';
import { EcwidLogo, EcwidSmallLogo } from './EcwidLogo';
import SidebarElement from './SidebarElement';

export default {
  code: INTEGRATION_CODE_ECWID,
  logo: EcwidLogo,
  small_logo: EcwidSmallLogo,
  sidebar_element: SidebarElement,
};
