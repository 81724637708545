import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import { Check } from '../../../lib/svg-images';
import './Checkbox.less';

export default function Checkbox({ checked, label, onChange, disabled, className }) {
  const [value, setValue] = useState(checked);
  const class_name = classNames(
    'Checkbox',
    {
      Checkbox_checked: value,
    },
    className,
  );
  useEffect(() => {
    setValue(checked);
  }, [checked]);
  function onClick() {
    if (!disabled) {
      onChange && onChange(!value);
      setValue(!value);
    }
  }
  return (
    <Form.Group className={class_name} onClick={onClick}>
      <Check checked={value} disabled={disabled} />
      <Form.Label>{label}</Form.Label>
    </Form.Group>
  );
}
