import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import {FormControlProps} from 'react-bootstrap/FormControl';
import './Input.less';

interface InputFeedbackProps {
  error?: string;
  text?: string;
}

interface InputProps extends FormControlProps {
  onFocus: React.EventHandler<any>;
  onBlur: React.EventHandler<any>;
  defaultValue?: string;
  label: string;
  error?: string;
  text?: string;
}

function InputFeedback(props: InputFeedbackProps): ReactElement {
  const {error, text} = props;
  if (error) {
    return <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>;
  }
  if (text) {
    return <Form.Text>{text}</Form.Text>;
  }

  return null;
}

const Input = React.forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const [active, setActive] = useState(!!(props.defaultValue || props.value));
  useEffect(() => {
    setActive(!!props.value);
  }, [props.value]);
  const {onFocus, onBlur, label, text, error, ...rest} = props;
  const root_class = classNames(
    'Input',
    {
      Input_active: active
    },
    props.className
  );

  const onFocusEvent = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
      setActive(!!e.target.value);
    },
    [onFocus]
  );
  const onBlurEvent = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
      setActive(!!e.target.value);
    },
    [onBlur]
  );

  return (
      <Form.Group className={root_class}>
          {label ? <Form.Label>{label}</Form.Label> : null}
          <Form.Control ref={ref} {...rest} onFocus={onFocusEvent} onBlur={onBlurEvent} />
          <InputFeedback error={error} text={text} />
      </Form.Group>
  );
});

export default Input;
