import {INTEGRATION_CODE_AMOCRM} from '../../../../common/constants';
import {AmoCRMLogo, AmoCRMSmallLogo} from './AmoCRMLogo';
import SidebarElement from './SidebarElement';

export default {
  code: INTEGRATION_CODE_AMOCRM,
  logo: AmoCRMLogo,
  small_logo: AmoCRMSmallLogo,
  sidebar_element: SidebarElement
};
