export function emailValidate(value) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi.test(value);
}

export function websiteValidate(value) {
  return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gi.test(
    value,
  );
}

export function usernameValidate(value) {
  return /^[a-zA-Z0-9._-]+$/gi.test(value);
}

export function usernameLengthValidate(value) {
  return value.length >= 5 && value.length <= 30;
}

export function passwordLengthValidate(value) {
  return value.length >= 6;
}
