import i18n from 'i18next';
import moment from 'moment';
import { updateMomentLocale } from '../../common/i18n';
import { initReactI18next } from 'react-i18next';

export function addLocaleData(lng, resources) {
  updateMomentLocale();
  moment.locale(lng);
  return i18n.use(initReactI18next).init({
    defaultNS: 'client',
    lng,
    fallbackLng: lng,
    interpolation: {
      escapeValue: false,
      prefix: '%{',
      suffix: '}',
    },
    resources,
  });
}

export default i18n;
