import React, { useCallback, useRef } from 'react';
import TextInput from '../Input';
import { ArrowDown } from '../../../lib/svg-images';
import './styles/Input.less';

export default function Input(props) {
  const { disabled, readOnly, onClick, ...rest } = props;
  const ref = useRef(null);
  const onInputClick = useCallback(
    (event) => {
      if (disabled || readOnly || (event.type == 'mousedown' && event.button !== 0)) return;
      ref.current.focus();
      event.stopPropagation();
      event.preventDefault();
      onClick();
    },
    [onClick, disabled, readOnly],
  );
  return (
    <div className="DropdownInput">
      <TextInput
        ref={ref}
        {...rest}
        type="text"
        readOnly
        onMouseDown={onInputClick}
        onTouchEnd={onInputClick}
      />
      <ArrowDown />
    </div>
  );
}
