import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import EventChannel from './event-channel';

export function useFetch(fetcher, deps = []) {
  let ignore = false;
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState(null);
  let [error, setError] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const result = await fetcher();
        if (!ignore) {
          setData(result);
          setLoading(false);
        }
      } catch (e) {
        if (!ignore) {
          setError(e);
          setLoading(false);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, deps);
  return { data, loading, error };
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Срабатывает на изменение фильтров
 */
export function useFilters(callback) {
  const { account: account_id } = useParams();
  const location = useLocation();
  const query_params = queryString.parse(location.search);
  const did_mount = useRef(false);

  useEffect(() => {
    if (did_mount.current) {
      callback();
    } else {
      did_mount.current = true;
    }
  }, [account_id, query_params.tab, query_params.query]);
}

/**
 * Срабатывает на сообщения в EventChannel
 * @param handler
 * @param message_type
 * @param [deps]
 */
export function useEventChannel(handler, message_type, deps = []) {
  useEffect(() => {
    let channel = EventChannel.getInstance();
    channel.on(message_type, handler);
    return () => {
      channel.off(message_type, handler);
      channel = null;
    };
  }, deps);
}

export function useCopyCallback(getter, deps) {
  return useCallback(() => {
    const area = document.createElement('textarea');
    area.style.position = 'absolute';
    area.style.left = '-1000px';
    area.value = getter();
    document.body.append(area);
    area.select();
    area.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(area);
  }, deps);
}

export function useNetworkStatus() {
  const [state, setState] = useState(navigator.onLine);
  const setOnline = useCallback(() => {
    setState(true);
    console.log('Changed to true');
  }, []);
  const setOffline = useCallback(() => {
    setState(false);
    console.log('Changed to false');
  }, []);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return state;
}

export function useIsRtl() {
  return {
    isRtl: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
  };
}
