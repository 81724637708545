import React from 'react';
import './ReCaptchaLegal.less';

export default function ReCaptchaLegal() {
  return (
    <div className="ReCaptchaLegal">
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  );
}
