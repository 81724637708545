export const PLATFORM_TYPE_IG = 1;
export const PLATFORM_TYPE_VK = 2;
export const PLATFORM_TYPE_FB = 3;
export const PLATFORM_TYPE_TG = 4;
export const PLATFORM_TYPE_VIBER = 5;
export const PLATFORM_TYPE_QCHAT = 6;
export const PLATFORM_TYPE_EMAIL = 7;
export const PLATFORM_TYPE_WA = 8;
export const PLATFORM_TYPE_INFO = 100;

export const PLATFORM_NAME_FB = 'fb';
export const PLATFORM_NAME_VK = 'vk';
export const PLATFORM_NAME_TG = 'tg';
export const PLATFORM_NAME_IG = 'ig';
export const PLATFORM_NAME_VIBER = 'viber';
export const PLATFORM_NAME_QCHAT = 'qchat';
export const PLATFORM_NAME_EMAIL = 'email';
export const PLATFORM_NAME_WA = 'wa';
export const PLATFORM_NAME_INFO = 'info';

export const PLATFORM_FULL_NAME_FB = 'Facebook';
export const PLATFORM_FULL_NAME_VK = 'VK';
export const PLATFORM_FULL_NAME_TG = 'Telegram';
export const PLATFORM_FULL_NAME_IG = 'Instagram';
export const PLATFORM_FULL_NAME_VIBER = 'Viber';
export const PLATFORM_FULL_NAME_QCHAT = 'Quickley Chat';
export const PLATFORM_FULL_NAME_EMAIL = 'Email';
export const PLATFORM_FULL_NAME_WA = 'WhatsApp Business';
export const PLATFORM_FULL_NAME_INFO = 'Info Channel';

export const PLATFORM_LINK_PREFIX = {
  [PLATFORM_TYPE_IG]: 'https://www.instagram.com/',
  [PLATFORM_TYPE_VK]: 'https://vk.me/-',
  [PLATFORM_TYPE_FB]: 'https://m.me/',
  [PLATFORM_TYPE_TG]: 'https://t.me/',
  [PLATFORM_TYPE_VIBER]: 'viber://pa?chatURI=',
  [PLATFORM_TYPE_WA]: 'https://wa.me/',
};

export const PERSON_CONTACT_TYPE = {
  IG: 'ig',
  VK: 'vk',
  FB: 'fb',
  TG: 'tg',
  VIBER: 'viber',
  QCHAT: 'qchat',
  EMAIL: 'email',
  PHONE: 'phone',
  IG_COMMENT: 'ig_comment',
};
export const ATTACH_TYPE_UNKNOWN = 0;
export const ATTACH_TYPE_FILE = 1;
export const ATTACH_TYPE_PHOTO = 2;
export const ATTACH_TYPE_VIDEO = 3;
export const ATTACH_TYPE_AUDIO = 4;
export const ATTACH_TYPE_LOCATION = 5;
export const ATTACH_TYPE_ANIMATION = 6;
export const ATTACH_TYPE_CONTACT = 7;
export const ATTACH_TYPE_STICKER = 8;
export const ATTACH_TYPE_LINK_PREVIEW = 9;
export const ATTACH_TYPE_MARKDOWN = 10;
export const ATTACH_TYPE_PRODUCT = 11;
export const ATTACH_TYPE_STORY_MENTION = 12;

export const type2name = {
  [PLATFORM_TYPE_VK]: PLATFORM_NAME_VK,
  [PLATFORM_TYPE_FB]: PLATFORM_NAME_FB,
  [PLATFORM_TYPE_IG]: PLATFORM_NAME_IG,
  [PLATFORM_TYPE_TG]: PLATFORM_NAME_TG,
  [PLATFORM_TYPE_VIBER]: PLATFORM_NAME_VIBER,
  [PLATFORM_TYPE_QCHAT]: PLATFORM_NAME_QCHAT,
  [PLATFORM_TYPE_EMAIL]: PLATFORM_NAME_EMAIL,
  [PLATFORM_TYPE_WA]: PLATFORM_NAME_WA,
  [PLATFORM_TYPE_INFO]: PLATFORM_NAME_INFO,
};

export const type2fullName = {
  [PLATFORM_TYPE_VK]: PLATFORM_FULL_NAME_VK,
  [PLATFORM_TYPE_FB]: PLATFORM_FULL_NAME_FB,
  [PLATFORM_TYPE_IG]: PLATFORM_FULL_NAME_IG,
  [PLATFORM_TYPE_TG]: PLATFORM_FULL_NAME_TG,
  [PLATFORM_TYPE_VIBER]: PLATFORM_FULL_NAME_VIBER,
  [PLATFORM_TYPE_QCHAT]: PLATFORM_FULL_NAME_QCHAT,
  [PLATFORM_TYPE_EMAIL]: PLATFORM_FULL_NAME_EMAIL,
  [PLATFORM_TYPE_WA]: PLATFORM_FULL_NAME_WA,
  [PLATFORM_TYPE_INFO]: PLATFORM_FULL_NAME_INFO,
};

export const type2contactType = {
  [PLATFORM_TYPE_VK]: PERSON_CONTACT_TYPE.VK,
  [PLATFORM_TYPE_FB]: PERSON_CONTACT_TYPE.FB,
  [PLATFORM_TYPE_IG]: PERSON_CONTACT_TYPE.IG,
  [PLATFORM_TYPE_TG]: PERSON_CONTACT_TYPE.TG,
  [PLATFORM_TYPE_VIBER]: PERSON_CONTACT_TYPE.VIBER,
  [PLATFORM_TYPE_QCHAT]: PERSON_CONTACT_TYPE.QCHAT,
  [PLATFORM_TYPE_EMAIL]: PERSON_CONTACT_TYPE.EMAIL,
  [PLATFORM_TYPE_WA]: PERSON_CONTACT_TYPE.PHONE,
};

export const PLATFORMS_TO_SYNCING = [PLATFORM_TYPE_VK, PLATFORM_TYPE_FB, PLATFORM_TYPE_IG];

export const VK_PERMISSIONS =
  +262144 + // Доступ к группам пользователя.
  131072 + // Доступ к документам.
  8192 + // Доступ к обычным и расширенным методам работы со стеной.
  134217728 + // Товары
  16 + // Видео
  4 + // Фото
  65536; // Доступ к API всегда

export const VK_GROUP_PERMISSIONS =
  +262144 + // Доступ к администрированию сообщества.
  131072 + // Доступ к документам.
  4096 + // Доступ к сообщениям сообщества.
  4; // Доступ для отправки фото.

export const ORIGIN = typeof window !== 'undefined' ? window.location.origin : process.env.ADDRESS;
export const VK_AUTH_URI = 'https://oauth.vk.com/authorize';
export const VK_VERIFY_URL = ORIGIN + '/channels/vk';
export const VK_RENEW_URL = ORIGIN + '/channels/vk/renew/';
export const FB_RENEW_URL = ORIGIN + '/channels/fb/renew/';
export const IG_RENEW_URL = ORIGIN + '/channels/ig/renew/';

/**
 * Цвета тегов, первые два используются для тегов по-умолчанию
 */
export const TAG_COLORS = [0xd84f84, 0x00c6ff, 0x67528d, 0xffa439, 0x4acb5e, 0x333333];
export const TAG_MAX_LENGTH = 25;

/**
 * Типы внешних сущностей
 * Поменяй type_check на external_entity, если меняешь типы
 */
export const EXTERNAL_ENTITY_TYPE = {
  POST: 100,
  // MARKET: 101
};

/**
 * Типы наших сообщений
 * Поменяй message_check и type_message на message, если меняешь типы
 */
export const MESSAGE_TYPE = {
  ...EXTERNAL_ENTITY_TYPE,
  DIRECT: 200,
  ACTION: 300,
};

/**
 * Типы "персон"
 * При изменении не забыть поменять check на колонке type в таблице person
 */
export const PERSON_TYPE = {
  USER: 1,
  GROUP: 2,
  OPERATOR: 3,
};

export const THREAD_UNAVAILABLE_REASON = {
  DELETED: 1,
  SERVICE_WINDOW_CLOSED: 24,
  AGENT_WINDOW_CLOSED: 7,
};

export const MAX_MESSAGE_IN_EXPANDED_THREAD = 5;

/**
 * Основные типы для ошибок.
 * Все ошибки связанные с какой-то одной сущностью должны иметь одинаковый бит в своем коде
 */
export const EMAIL_ERROR = 0x01000000;
export const PASSWORD_ERROR = 0x02000000;
export const CAPTCHA_ERROR = 0x04000000;
export const PLATFORM_ERROR = 0x08000000;
export const SUBSCRIPTION_ERROR = 0x10000000;
export const PROFILE_ERROR = 0x20000000;

export const VK_ERROR = 0x00010000;
export const TG_ERROR = 0x00020000;

export const ERROR_EMAIL_IS_REQUIRED = EMAIL_ERROR | 1;
export const ERROR_INCORRECT_EMAIL = EMAIL_ERROR | 2;
export const ERROR_EMAIL_ALREADY_REGISTERED = EMAIL_ERROR | 3;

export const ERROR_PASSWORD_IS_REQUIRED = PASSWORD_ERROR | 1;

export const ERROR_CAPTCHA_NOT_PASSED = CAPTCHA_ERROR | 1;

export const ERROR_UNKNOWN_ACCOUNT = EMAIL_ERROR | PASSWORD_ERROR | 1;

export const PLATFORM_ERROR_PARENT_DELETED = PLATFORM_ERROR | VK_ERROR | 9;

export const PLATFORM_ERROR_INCORRECT_TOKEN = PLATFORM_ERROR | TG_ERROR | 1;

export const SUBSCRIPTION_ERROR_MANAGERS_UNPAID = SUBSCRIPTION_ERROR | 1;

export const ERROR_PROFILE_NOT_CONFIRMED = PROFILE_ERROR | 1;
export const ERROR_PROFILE_LOCKED = PROFILE_ERROR | 2;

export const ACCOUNT_ERROR_INTEGRATION_FAILED = -1;
export const ACCOUNT_ERROR_NOT_ADMIN = -2;
export const ACCOUNT_ERROR_CANT_CREATE_CONTENT = -3;
export const ACCOUNT_ERROR_INVALID_WEBHOOK_SERVER = -4;

// Don't forget add the new state to the "common/types.ts"
export const ONBOARDING_STATE_COMPLETE = 0;
export const ONBOARDING_STATE_ADD_CHANNELS = 1;
export const ONBOARDING_STATE_SYNCING = 2;
export const ONBOARDING_STATE_MANAGER_USER_INFO = 11;
export const ONBOARDING_STATE_OWNER_USER_INFO = 21;
// Don't forget add the new state to the "common/types.ts"

export const TEAM_ROLE_MANAGER = 1;
export const TEAM_ROLE_ADMIN = 2;
export const TEAM_ROLE_OWNER = 3;

export const TEAM_MEMBER_STATUS_INACTIVE = 0;
export const TEAM_MEMBER_STATUS_ACTIVE = 1;

export const DEFAULT_USERPIC_URL = '/images/default_userpic.svg';
export const DEFAULT_ACCOUNT_PIC_URL = '/images/default_account_pic.svg';

export const PRODUCTS = {
  INBOX: 'inbox',
  ACTIVE_CONTACTS: 'active_contacts',
  SEATS: 'seats',
  WABA: 'waba',
  WHATSAPP_CONVERSATIONS_USER_INITIATED: 'whatsapp_conversations_user_initiated',
  WHATSAPP_CONVERSATIONS_BUSINESS_INITIATED: 'whatsapp_conversations_business_initiated',
  WHATSAPP_CONVERSATIONS_MARKETING: 'whatsapp_conversations_marketing',
  WHATSAPP_CONVERSATIONS_UTILITY: 'whatsapp_conversations_utility',
  WHATSAPP_CONVERSATIONS_AUTHENTICATION: 'whatsapp_conversations_authentication',
  WHATSAPP_CONVERSATIONS_SERVICE: 'whatsapp_conversations_service',
};

export const MESSAGE_EDIT_WINDOW = 24 * 60 * 60 * 1000;

export const PERSON_STATUS = {
  OFFLINE: 0,
  ONLINE: 1,
  IN_CHAT: 2,
};
export const PERSON_ONLINE_TIMEOUT = 1000 * 60;

export const WHATS_APP_CONNECT_STATUS = {
  NONE: 0,
  REQUEST: 1,
  AVAILABLE: 2,
  TOKEN_REQUIRED: 3,
};

/* COLORS */
export const BRAND_COLOR = '#0072ff';
export const EMERALD = '#4ACB5E';
export const ORANGE = '#ef6544';
export const FIRE_ROSE_COLOR = '#ff017d';
export const PURPLE = '#6f56e5';
export const PLATINUM_COLOR = '#1a2b49';
export const SMOKE_COLOR = '#8191a5';
export const CARBON_COLOR = '#c1ccdb';
export const DIRTY_JON_SNOW_COLOR = '#e6e9f1';
export const SNOW_COLOR = '#f9fbfc';

export const VK_COLOR = '#4680C2';
export const FB_COLOR = '#1877F2';
export const TG_COLOR = '#36AADD';
export const VIBER_COLOR = '#665CAC';
export const IG_COLOR = '#F10176';
export const WA_COLOR = '#25D366';
/* END COLORS */

/* INTEGRATIONS */
export const INTEGRATION_CODE_INSALES = 'insales';
export const INTEGRATION_CODE_AMOCRM = 'amocrm';
export const INTEGRATION_CODE_ECWID = 'ecwid';
export const INTEGRATION_CODE_SHOPIFY = 'shopify';
export const INTEGRATION_CODE_BITRIX24 = 'b24';
export const INTEGRATION_CODE_MONDAY = 'monday';
export const INTEGRATION_CODE_DIALOG360 = '360dialog';

export const INTEGRATION_ERROR_UNAUTHORIZED = -1;
/* END INTEGRATIONS */
