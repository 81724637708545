import React from 'react';
import Input from './Input';
import './FormFields.less';

const InputField = React.forwardRef((props, ref) => {
  const { input, meta, ignoretouchstate, ...rest } = props;
  const error =
    (meta.touched || ignoretouchstate) &&
    !meta.dirtySinceLastSubmit &&
    !meta.submitting &&
    (meta.error || meta.submitError);
  return <Input {...input} {...rest} ref={ref} isInvalid={!!error} error={error} />;
});

export default InputField;
