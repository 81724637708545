import React, {ReactNode} from 'react';
import {Field} from 'react-final-form';
import InputField from '../../../form-fields/InputField';
import './ConnectForm.less';

export default function ConnectForm(): ReactNode {
  return (
      <div className="ShopifyConnectForm__name">
          <Field
        name="store_name"
        type="text"
        placeholder="your-store-name"
        autoFocus
        component={InputField}
      />
          <div className="ShopifyConnectForm__domain">.myshopify.com</div>
      </div>
  );
}
