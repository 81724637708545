import AmoCRM from './AmoCRM';
import Ecwid from './Ecwid';
import Shopify from './Shopify';
import Bitrix24 from './Bitrix24';
import InSales from './InSales';
import Monday from './Monday';

const appByCode = {
  [AmoCRM.code]: AmoCRM,
  [Ecwid.code]: Ecwid,
  [Shopify.code]: Shopify,
  [Bitrix24.code]: Bitrix24,
  [InSales.code]: InSales,
  [Monday.code]: Monday,
};

const apps = Object.freeze({
  getByCode: (code) => appByCode[code],
});

export default apps;
