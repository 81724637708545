import React from 'react';
import boot from '../lib/app-boot';
import { dummy, lng } from '../reducers/common';
import {
  CompleteSignUp,
  Login,
  LoginMfaCheck,
  RequestDemo,
  RestorePassword,
  SignUp,
  UpdatePassword,
} from '../components/Auth';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ReCaptchaProvider } from '../components/ReCaptcha';
import qs from 'query-string';
import { Logo } from '../lib/svg-images';
import { useLocation } from 'react-router';
import IntegrationsPairingLogo from '../components/IntegrationPairingLogo';
import '../styles/auth-page.less';

function AuthPageLogo() {
  const { search } = useLocation();
  const { app_code } = qs.parse(search);
  return (
    <div className="AuthPageLogo">
      {app_code ? <IntegrationsPairingLogo code={app_code} /> : <Logo width="68" height="64" />}
    </div>
  );
}

function AuthPage() {
  const { t } = useTranslation();

  return (
    <ReCaptchaProvider>
      <div className="AuthPage">
        <div className="AuthPage__content">
          <AuthPageLogo />
          <Switch>
            <Route
              exact
              path="/demo"
              render={() => (
                <React.Fragment>
                  <h1>
                    <Trans i18nKey="auth.demo_title" components={[<b />]} />
                  </h1>
                  <RequestDemo />
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/login"
              render={(props) => (
                <React.Fragment>
                  <h1>
                    <Trans i18nKey="auth.login_title" components={[<b />]} />
                  </h1>
                  <Login {...props} />
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/check"
              render={(props) => (
                <React.Fragment>
                  <h1>
                    <Trans i18nKey="auth.login_title" components={[<b />]} />
                  </h1>
                  <LoginMfaCheck {...props} />
                </React.Fragment>
              )}
            />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/complete_signup" component={CompleteSignUp} />
            <Route
              exact
              path="/restore"
              render={() => (
                <React.Fragment>
                  <h1>{t('auth.restore_title')}</h1>
                  <RestorePassword />
                </React.Fragment>
              )}
            />
            <Route
              path="/restore/:token"
              render={(props) => (
                <React.Fragment>
                  <h1>{t('auth.update_password_title')}</h1>
                  <UpdatePassword {...props} />
                </React.Fragment>
              )}
            />
            <Route
              path="/unsubscribe"
              render={(props) => (
                <React.Fragment>
                  <h1>{t('auth.unsubscribed_title')}</h1>
                  <p>{t('auth.unsubscribed_subtitle')}</p>
                </React.Fragment>
              )}
            />
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    </ReCaptchaProvider>
  );
}

boot(
  {
    module_name: dummy,
    country_code: dummy,
    entry_data: dummy,
    ...lng,
  },
  [],
  AuthPage,
);
