import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import InputField from '../form-fields/InputField';
import DropdownField from '../form-fields/DropdownField';
import CheckboxGroupField from '../form-fields/CheckboxGroupField';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { emailValidate } from '../../../common/validations';
import RPC from '../../../common/rpc';
import createFocusOnErrorDecorator from 'final-form-focus';
import './styles/RequestDemo.less';
import Alert from 'react-bootstrap/Alert';
import { catchFormError } from '../../lib/forms';

const rpc = RPC('/api/v1/');
const focusOnError = createFocusOnErrorDecorator();

export default function RequestDemo() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const onSubmit = async (values) => {
    const errors = {};

    const info = [
      'full_name',
      'email',
      'company_name',
      'job_title',
      'company_size',
      'purpose',
    ].reduce((memo, field) => {
      const value = values[field];

      if (field === 'purpose') {
        if (!value || !value.length) {
          errors[field] = t('auth.err_invalid_purpose');
        } else {
          memo[field] = value.map((v) => v.label);
        }
      } else {
        if (!value) {
          errors[field] = t('validation.required');
        } else {
          memo[field] = value;
        }
      }

      return memo;
    }, {});

    if (info.email && !emailValidate(info.email)) errors.email = t('validation.invalid_email');

    if (Object.keys(errors).length) return errors;

    try {
      await rpc('invite.add', info);
      setSuccess(true);
    } catch (e) {
      return catchFormError(e);
    }
  };
  const company_size = [
    t('auth.demo_company_size_alone'),
    '2-5',
    '6-10',
    '11-50',
    '51-100',
    '100-500',
    '500+',
  ];
  const purpose = [
    t('auth.demo_purpose_support'),
    t('auth.demo_purpose_chat'),
    t('auth.demo_purpose_sales'),
    t('auth.demo_purpose_comments'),
    t('auth.demo_purpose_social_net'),
    t('auth.demo_purpose_whatsapp'),
  ];

  const renderForm = ({ handleSubmit, submitError, submitting, pristine }) => (
    <form onSubmit={handleSubmit} className="RequestDemo">
      <p className="RequestDemo__text">{t('auth.demo_text')}</p>
      <Field
        label={t('auth.full_name')}
        name="full_name"
        autoFocus
        maxLength="100"
        component={InputField}
        type="text"
      />
      <Field
        label={t('global.email')}
        name="email"
        maxLength="100"
        component={InputField}
        type="text"
      />
      <Field
        label={t('auth.company_name')}
        name="company_name"
        maxLength="100"
        component={InputField}
        type="text"
      />
      <Field
        label={t('auth.demo_job_title')}
        name="job_title"
        maxLength="100"
        component={InputField}
        type="text"
      />
      <Field
        label={t('auth.demo_company_size')}
        name="company_size"
        component={DropdownField}
        items={company_size.map((i) => ({ label: i, value: i }))}
      />
      <FieldArray
        label={t('auth.demo_purpose')}
        name="purpose"
        component={CheckboxGroupField}
        items={purpose.map((i) => ({ label: i }))}
      />
      <Alert show={!!submitError} variant="danger">
        {submitError}
      </Alert>
      <div className="RequestDemo__footer">
        <Link to="/login">{t('auth.have_profile')}</Link>
        <Button type="submit" disabled={submitting || pristine}>
          {t('global.send')}
        </Button>
      </div>
    </form>
  );

  if (success) {
    return <div className="RequestDemo RequestDemo__success">{t('auth.demo_success')}</div>;
  } else {
    return (
      <Form
        onSubmit={onSubmit}
        render={renderForm}
        decorators={[focusOnError]}
        mutators={{
          ...arrayMutators,
        }}
      />
    );
  }
}
