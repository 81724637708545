import React, {ReactElement, ReactNode} from 'react';
import Button, {ButtonProps} from 'react-bootstrap/Button';
import classNames from 'classnames';
import {Spinner} from '../../lib/svg-images';
import './WaitingButton.less';

interface IWaitingButtonProps extends ButtonProps {
  waiting?: boolean;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
}

export default function WaitingButton(props: IWaitingButtonProps): ReactElement {
  const {waiting, children, disabled, className, ...rest} = props;
  const class_name = classNames('WaitingButton', {
    WaitingButton_active: waiting,
    [className]: !!className
  });
  return (
      <Button className={class_name} {...rest} disabled={disabled || waiting}>
          {children}
          {waiting ? (
              <Spinner className="WaitingButton__spinner" width={16} height={16} color="#FFF" />
      ) : null}
      </Button>
  );
}
