import React, {ReactElement} from 'react';
import Apps from '../Apps';
import {Logo} from '../../lib/svg-images';
import './IntegrationPairingLogo.less';

type IntegrationPairingLogoProps = {
  code: string;
};

export default function IntegrationPairingLogo({
  code
}: IntegrationPairingLogoProps): ReactElement {
  const app = Apps.getByCode(code);
  const AppLogo = app.small_logo;
  return (
      <div className="IntegrationPairingLogo">
          <Logo width="68" height="64" />
          <svg
        width="30"
        height="30"
        fill="none"
        viewBox="0 0 30 30"
        className="IntegrationPairingLogo__pair"
      >
              <path
          fill="#C1CCDB"
          /* eslint-disable-next-line max-len */
          d="M4.268 12.5H18.75a1.25 1.25 0 000-2.5H4.268l2.866-2.866a1.25 1.25 0 00-1.768-1.768l-5 5c-.029.03-.056.06-.083.092l-.033.044-.04.054-.032.054-.03.05-.028.058-.025.054-.02.056c-.007.02-.015.039-.021.06-.006.018-.01.037-.015.056l-.015.062-.01.066-.008.055a1.257 1.257 0 000 .246l.008.055.01.066.015.062.015.057.02.06c.008.018.014.037.021.055l.025.054c.01.02.018.039.028.057l.03.05c.01.019.02.037.033.055.012.019.026.036.04.054a1.238 1.238 0 00.116.136c-.001 0-.001 0 0 0l5 5a1.25 1.25 0 101.767-1.768L4.268 12.5zM29.717 19.542c.012-.014.022-.03.033-.044l.04-.054.033-.054.03-.05.027-.058.025-.054.02-.056c.007-.02.015-.039.021-.059.006-.019.01-.038.014-.057l.016-.062.01-.066.008-.055a1.255 1.255 0 000-.246l-.008-.055-.01-.066-.015-.062-.015-.057-.02-.06-.021-.055-.025-.054-.028-.057c-.009-.017-.02-.034-.03-.05-.01-.018-.02-.037-.033-.055-.012-.018-.026-.036-.039-.053l-.033-.045a1.266 1.266 0 00-.082-.09l-.001-.002-5-5a1.25 1.25 0 10-1.768 1.768l2.866 2.866H11.25a1.25 1.25 0 000 2.5h14.482l-2.866 2.866a1.25 1.25 0 101.768 1.768l5-5 .001-.001c.029-.03.056-.06.082-.09z"
        />
          </svg>
          <AppLogo width="64" height="64" />
      </div>
  );
}
