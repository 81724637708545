import React, { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputField from '../form-fields/InputField';
import { Field, Form } from 'react-final-form';
import { catchFormError, clear } from '../../lib/forms';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import createFocusOnErrorDecorator from 'final-form-focus';
import rpc from '../../../common/rpc';
import { emailValidate } from '../../../common/validations';
import './styles/RestorePassword.less';
import { ReCaptchaLegal } from '../ReCaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const RPC = rpc('/api/v1/');
const focusOnError = createFocusOnErrorDecorator();

const RestorePassword = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onSubmit = useCallback(
    async (values, form) => {
      if (!emailValidate(values.email)) {
        return {
          email: t('validation.invalid_email'),
        };
      }

      try {
        const token = await executeRecaptcha('restore_password');
        await RPC('profile.restorePassword', {
          ...values,
          captcha: {
            action: 'restore_password',
            token,
          },
        });
        setSuccess(true);
      } catch (e) {
        return catchFormError(e);
      }
    },
    [executeRecaptcha],
  );
  const renderForm = ({ handleSubmit, submitError, submitting, pristine }) => (
    <form onSubmit={handleSubmit}>
      <Field
        label={t('global.email')}
        name="email"
        autoFocus
        maxLength="100"
        component={InputField}
        type="text"
      />
      <Alert show={!!submitError} variant="danger">
        {submitError}
      </Alert>
      <div className="RestorePassword__footer">
        <Button type="submit" disabled={submitting || pristine}>
          {t('auth.restore_btn')}
        </Button>
      </div>
      <ReCaptchaLegal />
    </form>
  );
  if (success) {
    return <div className="RestorePassword__success">{t('auth.restore_success')}</div>;
  } else {
    return (
      <Form
        onSubmit={onSubmit}
        render={renderForm}
        mutators={{ clear }}
        decorators={[focusOnError]}
      />
    );
  }
};

export default RestorePassword;
