import React from 'react';
import InputField from '../form-fields/InputField';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import queryString from 'query-string';
import rpc from '../../../common/rpc';
import { catchFormError } from '../../lib/forms';
import './styles/LoginMfaCheck.less';

const RPC = rpc('/api/v1/');

const LoginMfaCheck = (props) => {
  const { t } = useTranslation();
  const onSubmit = async (values) => {
    try {
      const query = queryString.parse(props.location.search);
      await RPC('profile.code', {
        token: query.token,
        code: values.code,
      });
      window.location = '/';
    } catch (e) {
      return catchFormError(e);
    }
  };
  const renderForm = ({ handleSubmit, submitError, submitting, pristine }) => (
    <form onSubmit={handleSubmit}>
      <Field
        name="code"
        type="text"
        label={t('auth.check_enter_code')}
        autoFocus
        autoComplete="off"
        component={InputField}
      />
      <Alert show={!!submitError} variant="danger">
        {submitError}
      </Alert>
      <div className="LoginMfaCheck__footer">
        <Button type="submit" disabled={submitting || pristine}>
          {t('global.continue')}
        </Button>
      </div>
    </form>
  );
  return <Form onSubmit={onSubmit} render={renderForm} />;
};

export default LoginMfaCheck;
