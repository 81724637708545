import React, { useCallback, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import Input from './Input';
import List from './List';
import NativeList from './NativeList';
import { IS_MOBILE } from '../../../lib/constants';
import './styles/Dropdown.less';

export default function Dropdown(props) {
  const { label, disabled, readOnly, items, value, error, onChange, className } = props;
  const ref = useRef(null);
  const [selected, setSelected] = useState(items.find((item) => item.value === value) || value);
  const [opened, setOpened] = useState(false);
  const class_name = classNames('Dropdown', className, {
    Dropdown_opened: opened,
    Dropdown_disabled: disabled,
  });
  const onInputClick = useCallback(
    (event) => {
      setOpened(!opened);
    },
    [opened],
  );
  const handleDocumentClick = useCallback(
    (event) => {
      if (!ReactDOM.findDOMNode(ref.current).contains(event.target)) {
        setOpened(false);
      }
    },
    [ref],
  );
  const onSelect = useCallback(
    (event, item) => {
      if (item.disabled) return false;

      if (item.value !== (selected && selected.value) && onChange) {
        onChange(item);
      }

      setSelected(item);
      setOpened(false);
    },
    [selected, onChange],
  );
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
    };
  }, []);

  const ListComponent = IS_MOBILE ? NativeList : List;

  return (
    <Form.Group className={class_name} ref={ref}>
      <Input
        onClick={onInputClick}
        label={label}
        value={selected && selected.label}
        disabled={disabled}
        readOnly={readOnly}
        isInvalid={!!error}
        error={error}
      />
      {error ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : null}
      <ListComponent opened={opened} items={items} selected={selected} onSelect={onSelect} />
    </Form.Group>
  );
}
