import React from 'react';

export function Bitrix24SmallLogo(props) {
  return (
    <svg width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        fill="#275C9E"
        d="M0 17.593h7.312v-1.625H2.485c.653-2.66 4.731-3.233 4.731-6.212C7.216 8.163 6.116 7 3.856 7 2.42 7 1.21 7.43.35 7.844l.51 1.482c.765-.367 1.641-.717 2.708-.717.845 0 1.625.366 1.625 1.338C5.193 12.16.43 12.32 0 17.593zm7.534-2.565h4.683v2.565h1.88v-2.565h1.561v-1.56h-1.56V7.015h-1.37l-5.194 6.738v1.274zM9.653 13.5l2.66-3.584c-.016.303-.096 1.21-.096 2.055v1.497h-1.242c-.382 0-1.083.032-1.322.032z"
      />
      <path
        stroke="#275C9E"
        strokeWidth="1.284"
        d="M20.314 15.001A3.69 3.69 0 0024 11.315a3.69 3.69 0 00-3.686-3.687 3.69 3.69 0 00-3.687 3.687A3.69 3.69 0 0020.314 15z"
      />
      <path fill="#275C9E" d="M22.621 11.311h-2.025V9.286h-.546v2.57h2.571v-.545z" />
    </svg>
  );
}

export function Bitrix24Logo(props) {
  return (
    <svg
      width={141}
      height={25}
      viewBox="0 0 141 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M95.783 18.431h12.722v-2.827h-8.398c1.136-4.628 8.232-5.626 8.232-10.809 0-2.772-1.913-4.795-5.848-4.795-2.495 0-4.601.748-6.098 1.47l.887 2.577c1.33-.638 2.855-1.247 4.712-1.247 1.469 0 2.827.637 2.827 2.328 0 3.852-8.287 4.13-9.036 13.303zm13.109-4.462h8.149v4.462h3.271V13.97h2.716v-2.716h-2.716V.028h-2.384l-9.036 11.724v2.217zm3.687-2.66l4.628-6.237c-.027.527-.166 2.107-.166 3.576v2.605h-2.162c-.665 0-1.884.055-2.3.055z"
        fill="#275C9E"
      />
      <path
        d="M3.354 15.687V9.95h1.385c1.22 0 2.246.139 2.966.555.748.415 1.164 1.191 1.164 2.327 0 1.94-1.192 2.855-3.908 2.855H3.354zM0 18.431h4.878c5.405 0 7.373-2.384 7.373-5.681 0-2.245-.943-3.742-2.578-4.601-1.303-.693-2.993-.915-4.989-.915h-1.33V2.882h7.317l.886-2.715H0V18.43zm14.467 0h3.215l4.102-5.903a41.752 41.752 0 001.747-2.744h.055c-.055.776-.111 1.773-.111 2.827v5.82h3.243V5.321h-3.215L19.4 11.226a58.128 58.128 0 00-1.746 2.744h-.056c.056-.776.111-1.774.111-2.827v-5.82h-3.243v13.11zm17.822 0h3.298V8.065h3.88l.86-2.743h-12.03v2.743h3.992v10.366zM41.518 25h3.298v-6.597c.637.195 1.33.306 2.023.306 4.102 0 6.818-2.855 6.818-6.874 0-4.185-2.466-6.846-7.178-6.846-1.801 0-3.52.333-4.961.831V25zm3.298-9.368V7.927c.499-.139.97-.222 1.552-.222 2.356 0 3.908 1.33 3.908 4.13 0 2.522-1.247 4.13-3.686 4.13-.693 0-1.192-.111-1.774-.333zm10.782 2.8h3.215l4.101-5.904a41.624 41.624 0 001.747-2.744h.055c-.055.776-.11 1.773-.11 2.827v5.82h3.242V5.321h-3.215l-4.102 5.904a58.045 58.045 0 00-1.746 2.744h-.055c.055-.776.11-1.774.11-2.827v-5.82h-3.242v13.11zm14.828 0h3.298v-5.433h1.968c.388 0 .748.36 1.136 1.247l1.663 4.185h3.575l-2.383-5.016c-.416-.86-.86-1.358-1.525-1.608v-.055c1.11-.665 1.22-2.55 1.885-3.465.222-.277.527-.416.915-.416.25 0 .526.028.748.111V5.183c-.388-.138-.998-.194-1.358-.194-1.136 0-1.885.443-2.412 1.164-1.08 1.58-1.053 4.324-2.688 4.324h-1.524V5.322h-3.299v13.11zm19.04.277c1.802 0 3.437-.555 4.49-1.276l-.97-2.272c-.915.499-1.83.886-3.049.886-2.245 0-3.714-1.468-3.714-4.157 0-2.411 1.442-4.24 3.88-4.24 1.275 0 2.273.36 3.188 1.025V5.876c-.693-.444-1.885-.887-3.52-.887-3.908 0-6.929 2.91-6.929 6.984 0 3.797 2.356 6.736 6.624 6.736z"
        fill="#4DC6F4"
      />
      <path
        d="M132.868 13.922a6.421 6.421 0 006.414-6.415 6.421 6.421 0 00-6.414-6.414 6.422 6.422 0 00-6.415 6.414 6.422 6.422 0 006.415 6.415z"
        stroke="#275C9E"
        strokeWidth={1.284}
      />
      <path d="M136.883 7.501h-3.524V3.977h-.949V8.45h4.473V7.5z" fill="#275C9E" />
    </svg>
  );
}
