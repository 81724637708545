import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ListItem from './ListItem';
import classNames from 'classnames';
import './styles/List.less';

export default function List({ opened, items, selected, onSelect }) {
  const class_name = classNames('DropdownList', {
    DropdownList_opened: opened,
  });
  return (
    <div className={class_name}>
      <Scrollbars autoHide autoHeight autoHeightMin={0} autoHeightMax={200}>
        {items.map((item) => (
          <ListItem key={item.value} {...item} selected={selected} onSelect={onSelect} />
        ))}
      </Scrollbars>
    </div>
  );
}
