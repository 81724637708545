import React from 'react';
import propTypes from 'prop-types';
import { getDateString, getTimeAgoString, getTimeString } from '../../lib/time';
import classNames from 'classnames';

const { Provider, Consumer } = React.createContext();

export class TimeAgoProvider extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      now: Date.now(),
    };
  }
  componentDidMount() {
    this.timer = setInterval(() => this.setState({ now: Date.now() }), 10000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export function TimeAgo(props) {
  const { value, use_days, className, ...rest } = props;
  const class_name = classNames('TimeAgo', {
    [className]: !!className,
  });
  return (
    <Consumer>
      {({ now }) => (
        <span className={class_name} {...rest}>
          {getTimeAgoString(value, now, use_days)}
        </span>
      )}
    </Consumer>
  );
}

TimeAgo.propTypes = {
  value: propTypes.number.isRequired,
};

export function FullDate(props) {
  const { value, ...rest } = props;
  return (
    <Consumer>
      {({ now }) => (
        <span className="TimeAgo" {...rest}>
          {getDateString(value, now)}
        </span>
      )}
    </Consumer>
  );
}

FullDate.propTypes = {
  value: propTypes.number.isRequired,
};

export const Time = React.memo(function Time(props) {
  let { value, ...rest } = props;
  return (
    <span className="TimeAgo" {...rest}>
      {getTimeString(value)}
    </span>
  );
});
