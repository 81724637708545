import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import InputField from '../form-fields/InputField';
import { Field, Form } from 'react-final-form';
import { catchFormError, clear, password } from '../../lib/forms';
import Alert from 'react-bootstrap/Alert';
import { Trans, useTranslation } from 'react-i18next';
import rpc from '../../../common/rpc';
import { emailValidate } from '../../../common/validations';
import { ReCaptchaLegal } from '../ReCaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import WaitingButton from '../WaitingButton';
import Apps from '../Apps';
import { useFetch } from '../../lib/hooks';
import sendEvent from '../../lib/tracker';
import PhoneNumberField from '../form-fields/PhoneNumberField';
import './styles/SignUp.less';

const RPC = rpc('/api/v1/');

function SignUpForm({ onVerifyRequired }) {
  const { t } = useTranslation();
  const [entry_data, country_code] = useSelector((state) => [state.entry_data, state.country_code]);
  const location = useLocation();
  const { app_code, app_token, role, promo_code } = qs.parse(location.search);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onSubmit = useCallback(
    async (values, form) => {
      const errors = {};

      ['email', 'phone_number', 'workspace_display_name', 'person_name', 'password'].map(
        (field) => {
          if (!values[field]) {
            errors[field] = t(`auth.err_no_${field}`);
          }
        },
      );

      if (values.email && !emailValidate(values.email))
        errors.email = t('validation.invalid_email');

      if (Object.keys(errors).length) return errors;

      try {
        const recaptcha_token = await executeRecaptcha('signup');
        await RPC('profile.signup', {
          ...values,
          role,
          promo_code,
          captcha: {
            action: 'signup',
            token: recaptcha_token,
          },
          app: app_code
            ? {
                code: app_code,
                token: app_token,
              }
            : undefined,
        });
        sendEvent('Sign up');
        if (app_code) {
          const app = Apps.getByCode(app_code);
          window.location = app.redirect_uri || `/settings/project/integrations/${app_code}`;
        } else {
          onVerifyRequired(values.email);
        }
      } catch (e) {
        return catchFormError(e);
      }
    },
    [app_code, app_token, executeRecaptcha],
  );

  const { data: promo_code_info } = useFetch(async () => {
    if (promo_code) {
      return await RPC('billing.getPromoCode', {
        code: promo_code,
      });
    }
  }, [promo_code]);

  const renderForm = ({ handleSubmit, submitError, submitting, pristine, form }) => (
    <form onSubmit={handleSubmit}>
      <Field
        label={t('global.email')}
        name="email"
        maxLength="100"
        autoFocus
        component={InputField}
        type="text"
      />
      <Field
        label={t('global.phone')}
        name="phone_number"
        maxLength="20"
        defaultCountry={country_code}
        component={PhoneNumberField}
      />
      <Field
        name="workspace_display_name"
        maxLength="100"
        label={t('auth.company_name')}
        component={InputField}
        type="text"
      />
      <Field
        label={t('auth.full_name')}
        name="person_name"
        maxLength="100"
        component={InputField}
        type="text"
      />
      <Field
        label={t('global.password')}
        name="password"
        maxLength="100"
        component={InputField}
        type="password"
        validate={password(t)}
      />
      <Alert show={!!submitError} variant="danger">
        {submitError}
      </Alert>
      <div className="SignUp__footer">
        <Link to={`/login${location.search}`}>{t('auth.have_profile')}</Link>
        <WaitingButton type="submit" waiting={submitting} disabled={pristine}>
          {t('auth.signup_btn')}
        </WaitingButton>
      </div>
      <ReCaptchaLegal />
    </form>
  );

  return (
    <>
      {/*<FBLoginButton type={FBLoginButtonType.SIGNUP} />*/}
      {promo_code_info ? (
        <div className="SignUp__promoCode">
          {t('auth.signup_promo_code', { name: promo_code_info.name })}
        </div>
      ) : null}
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...entry_data }}
        render={renderForm}
        mutators={{ clear }}
      />
    </>
  );
}

export default function SignUp() {
  let { t } = useTranslation();
  let { search } = useLocation();
  let { app_code } = qs.parse(search);
  let [verify_email, setVerifyEmail] = useState(null);
  let headline;

  if (app_code) {
    headline = (
      <>
        <h1>{t(`integration.${app_code}.signup_title`)}</h1>
        <p>{t(`integration.${app_code}.signup_text`)}</p>
      </>
    );
  } else {
    headline = (
      <>
        <h1>
          <Trans i18nKey="auth.signup_title" components={[<b />]} />
        </h1>
        {verify_email ? <p>{t('auth.verify_signup', { email: verify_email })}</p> : null}
      </>
    );
  }

  const onVerifyRequired = useCallback(
    (email) => {
      setVerifyEmail(email);
    },
    [verify_email],
  );

  return (
    <div className="SignUp">
      {headline}
      {!verify_email ? <SignUpForm onVerifyRequired={onVerifyRequired} /> : null}
    </div>
  );
}
