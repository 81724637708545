import React from 'react';
import SidebarElement from '../../Sidebar/Element';
import { useTranslation } from 'react-i18next';

export default function (props) {
  const { t } = useTranslation();
  const { person, integration } = props;
  const {
    meta: { amocrm },
  } = person;
  const {
    meta: { host },
  } = integration;

  if (amocrm?.contact_id) {
    const link = `https://${host}/contacts/detail/${amocrm.contact_id}`;
    return (
      <SidebarElement>
        <SidebarElement.Header>{t('integration.amocrm.full_name')}</SidebarElement.Header>
        <SidebarElement.Body>
          <a href={link} target="_blank">
            {t('integration.amocrm.open_contact')}
          </a>
        </SidebarElement.Body>
      </SidebarElement>
    );
  } else {
    return null;
  }
}
