import React from 'react';
import Dropdown from './Dropdown';

export default function DropdownField(props) {
  const { input, meta, ignoretouchstate, ...rest } = props;
  const error =
    (meta.touched || ignoretouchstate) &&
    !meta.dirtySinceLastSubmit &&
    !meta.submitting &&
    (meta.error || meta.submitError);
  return (
    <Dropdown
      {...rest}
      error={error}
      value={input.value}
      onChange={(selected) => {
        input.onChange(selected.value);
      }}
    />
  );
}
