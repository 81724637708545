import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

export default function ReCaptchaProvider({ children }) {
  const { i18n } = useTranslation();
  return (
    <GoogleReCaptchaProvider reCaptchaKey={window.recaptcha_key} language={i18n.language}>
      {children}
    </GoogleReCaptchaProvider>
  );
}
