import React from 'react';
import PhoneInput from 'react-phone-number-input';
import {FieldProps} from 'react-final-form';
import Input from '../Input';
import 'react-phone-number-input/style.css';
import './PhoneNumberField.less';

interface PhoneNumberFieldProps extends FieldProps<any, any> {}

const PhoneNumberField = React.forwardRef(
  (props: PhoneNumberFieldProps, ref: React.ForwardedRef<any>) => {
    const {input, meta, ignoretouchstate, ...rest} = props;
    const error =
      (meta.touched || ignoretouchstate) &&
      !meta.dirtySinceLastSubmit &&
      !meta.submitting &&
      (meta.error || meta.submitError);
    return (
        <PhoneInput
        inputComponent={Input}
        international
        addInternationalOption={false}
        countrySelectProps={{unicodeFlags: true}}
        {...input}
        {...rest}
        isInvalid={meta.invalid}
        ref={ref}
        error={error}
      />
    );
  }
);

export default PhoneNumberField;
