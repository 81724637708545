import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import './styles/NativeList.less';

export default function NativeList({ opened, items, selected, onSelect }) {
  const ref = useRef(null);
  const class_name = classNames('DropdownNativeList', {
    DropdownNativeList_cover: /Android/i.test(navigator.userAgent),
  });
  useEffect(() => {
    if (opened) {
      try {
        let event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        ref.current.focus();
        ref.current.dispatchEvent(event);
      } catch (e) {
        if (document.createEvent) {
          let event = document.createEvent('MouseEvents');
          event.initMouseEvent(
            'mousedown',
            true,
            true,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null,
          );
          ref.current.dispatchEvent(event);
        } else if (ref.current.fireEvent) {
          ref.current.fireEvent('onmousedown');
        }
      }
    }
  }, [opened]);
  function onChange(event) {
    onSelect(
      event,
      items.find((item) => item.value == event.target.value),
    );
  }
  return (
    <select ref={ref} className={class_name} onChange={onChange}>
      {items.map((item) => {
        const option_props = {
          key: item.value,
          value: item.value,
          children: item.label,
        };
        if (item.value === (selected && selected.value)) option_props.selected = 'selected';
        return <option {...option_props} />;
      })}
    </select>
  );
}
