import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';
import {SENTRY_EVENT_LIMIT_BY_SESSION} from './constants';

export default function sendEvent(event: string, data?: object): void {
  if (window.__stage__ === 'production') {
    const {yaCounter55252984, dataLayer} = window;
    if (typeof yaCounter55252984 !== 'undefined') yaCounter55252984.reachGoal(event);
    if (typeof dataLayer !== 'undefined') dataLayer.push({event, ...data});
    mixpanel.track(event, data);
  }
}

export function loadTrackers(): void {
  if (window.__stage__ === 'production') {
    Sentry.init({
      dsn: 'https://a979e5a554bd4653b142f5d97804ea81@sentry.io/1837837',
      release: window.__release,
      environment: window.location.hostname,
      // eslint-disable-next-line func-names
      beforeSend: (function(): any {
        let client_errors_count = 0;
        return (event: any): any => {
          if (client_errors_count > SENTRY_EVENT_LIMIT_BY_SESSION) {
            return null;
          }
          client_errors_count++;
          return event;
        };
      })()
    });
    mixpanel.init('7f93f420e65404bd4ca0d3f3fb7a6d0a');
  }
}
