import React from 'react';

export function ShopifySmallLogo(props) {
  return (
    <svg width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        fill="#95BF47"
        d="M20.83 4.806a.228.228 0 00-.216-.198c-.09 0-1.872-.036-1.872-.036s-1.494-1.44-1.638-1.601c-.144-.144-.432-.108-.54-.072l-.756.234a5.366 5.366 0 00-.36-.882c-.522-1.008-1.314-1.548-2.25-1.548-.071 0-.125 0-.197.018-.036-.036-.054-.072-.09-.09-.414-.432-.936-.648-1.566-.63-1.206.036-2.412.918-3.402 2.466-.684 1.098-1.206 2.465-1.368 3.527-1.385.432-2.357.738-2.393.738-.702.216-.72.234-.81.9C3.336 8.136 1.5 22.336 1.5 22.336L16.906 25l6.677-1.656c-.018 0-2.735-18.412-2.753-18.538zm-5.796-1.421c-.36.108-.756.234-1.188.378 0-.612-.09-1.476-.36-2.196.918.144 1.368 1.188 1.548 1.818zm-1.997.612c-.81.252-1.692.521-2.574.791.252-.953.72-1.89 1.296-2.52.216-.233.522-.485.864-.63.36.703.432 1.693.414 2.359zM11.38.81c.288 0 .522.054.72.198a3.19 3.19 0 00-.936.738c-.774.828-1.368 2.106-1.602 3.347-.738.234-1.458.45-2.106.648C7.89 3.781 9.527.865 11.381.811z"
      />
      <path
        fill="#5E8E3E"
        d="M20.614 4.608c-.09 0-1.872-.036-1.872-.036s-1.494-1.44-1.637-1.601a.29.29 0 00-.198-.09V25l6.677-1.656-2.736-18.538c-.036-.126-.144-.198-.234-.198z"
      />
      <path
        fill="#fff"
        d="M13.199 8.046l-.774 2.898s-.864-.396-1.89-.324c-1.512.09-1.512 1.044-1.512 1.278.09 1.296 3.492 1.583 3.69 4.625.144 2.394-1.26 4.032-3.312 4.158-2.447.126-3.797-1.314-3.797-1.314l.522-2.214s1.368 1.026 2.447.954a.955.955 0 00.936-1.026c-.108-1.692-2.88-1.584-3.06-4.373-.143-2.34 1.386-4.698 4.77-4.914 1.314-.09 1.98.252 1.98.252z"
      />
    </svg>
  );
}

export function ShopifyLogo(props) {
  return (
    <svg
      width={88}
      height={25}
      viewBox="0 0 88 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M18.855 4.688a.222.222 0 00-.211-.193c-.088 0-1.826-.035-1.826-.035s-1.457-1.404-1.597-1.562c-.14-.14-.422-.106-.527-.07l-.737.228a5.242 5.242 0 00-.352-.86c-.509-.984-1.281-1.51-2.194-1.51a.72.72 0 00-.193.017c-.035-.035-.053-.07-.088-.088-.404-.42-.913-.632-1.527-.614C8.427.036 7.25.896 6.285 2.406 5.618 3.477 5.109 4.811 4.95 5.847c-1.352.421-2.3.72-2.335.72-.685.21-.702.228-.79.878C1.79 7.936 0 21.787 0 21.787l15.027 2.599 6.514-1.616c-.018 0-2.669-17.959-2.686-18.082zm-5.653-1.387c-.351.106-.738.229-1.159.37 0-.598-.088-1.44-.351-2.143.895.14 1.334 1.159 1.51 1.773zm-1.949.597c-.79.246-1.65.51-2.51.773.245-.93.702-1.844 1.264-2.458.21-.228.509-.474.842-.614.351.684.422 1.65.404 2.3zM9.638.791c.28 0 .509.053.702.193a3.112 3.112 0 00-.913.72c-.755.807-1.334 2.054-1.562 3.265-.72.228-1.422.44-2.054.632C6.232 3.688 7.83.844 9.638.791z"
          fill="#95BF47"
        />
        <path
          d="M18.642 4.495c-.088 0-1.826-.035-1.826-.035s-1.457-1.404-1.597-1.562a.282.282 0 00-.194-.088v21.576l6.513-1.616L18.87 4.688c-.035-.123-.14-.193-.228-.193z"
          fill="#5E8E3E"
        />
        <path
          d="M11.41 7.848l-.755 2.827s-.842-.386-1.843-.316c-1.475.088-1.475 1.018-1.475 1.246.088 1.264 3.406 1.545 3.6 4.512.14 2.335-1.23 3.932-3.23 4.055-2.388.123-3.705-1.281-3.705-1.281l.51-2.16s1.333 1.001 2.387.93a.931.931 0 00.912-1c-.105-1.65-2.808-1.545-2.984-4.266-.14-2.282 1.352-4.582 4.652-4.792 1.282-.088 1.931.245 1.931.245z"
          fill="#fff"
        />
        <path
          d="M30.354 13.87c-.755-.404-1.14-.755-1.14-1.23 0-.596.544-.982 1.386-.982.983 0 1.861.404 1.861.404l.685-2.107s-.632-.492-2.493-.492c-2.598 0-4.407 1.493-4.407 3.582 0 1.194.843 2.089 1.967 2.738.912.51 1.229.878 1.229 1.422 0 .562-.457 1.019-1.3 1.019-1.246 0-2.44-.65-2.44-.65l-.737 2.107s1.088.737 2.932.737c2.668 0 4.6-1.317 4.6-3.687-.018-1.281-.984-2.194-2.143-2.861zM40.992 9.428c-1.317 0-2.353.632-3.143 1.58l-.035-.017 1.141-5.97h-2.967l-2.896 15.204h2.967l.983-5.197c.386-1.966 1.404-3.177 2.352-3.177.667 0 .93.456.93 1.106 0 .404-.035.913-.122 1.317l-1.124 5.95h2.967l1.159-6.143c.123-.65.21-1.422.21-1.95.018-1.684-.86-2.703-2.422-2.703zM50.156 9.428c-3.58 0-5.95 3.23-5.95 6.83 0 2.3 1.421 4.16 4.09 4.16 3.51 0 5.88-3.142 5.88-6.83.018-2.123-1.228-4.16-4.02-4.16zM48.7 18.153c-1.018 0-1.44-.86-1.44-1.948 0-1.703.879-4.477 2.494-4.477 1.053 0 1.404.913 1.404 1.79 0 1.826-.895 4.635-2.458 4.635zM61.796 9.428c-2.001 0-3.142 1.773-3.142 1.773h-.036l.176-1.597H56.16a66.021 66.021 0 01-.597 3.95l-2.072 10.884h2.967l.825-4.406h.07s.615.386 1.738.386c3.494 0 5.776-3.581 5.776-7.198 0-2.001-.895-3.792-3.072-3.792zm-2.844 8.76c-.773 0-1.229-.438-1.229-.438l.492-2.774c.35-1.861 1.316-3.09 2.352-3.09.913 0 1.194.843 1.194 1.633 0 1.93-1.141 4.67-2.81 4.67zM69.117 5.162c-.948 0-1.703.755-1.703 1.72 0 .879.562 1.493 1.404 1.493h.036c.93 0 1.72-.632 1.738-1.72 0-.86-.58-1.493-1.475-1.493zM64.955 20.207h2.967L69.94 9.674h-2.985l-2 10.533zM77.508 9.657h-2.071l.105-.492c.176-1.018.773-1.914 1.773-1.914.527 0 .948.158.948.158l.58-2.334s-.51-.264-1.615-.264c-1.054 0-2.107.299-2.915.983-1.018.86-1.492 2.107-1.72 3.371l-.088.492h-1.387l-.439 2.247h1.387l-1.58 8.32h2.967l1.58-8.32h2.054l.421-2.247zM84.671 9.674s-1.86 4.687-2.686 7.25h-.035c-.053-.825-.737-7.25-.737-7.25h-3.125l1.79 9.673c.036.21.018.351-.07.492-.35.667-.93 1.316-1.615 1.79-.562.404-1.194.668-1.685.843L77.333 25c.597-.123 1.86-.632 2.914-1.615 1.352-1.264 2.616-3.23 3.897-5.899l3.634-7.812h-3.107z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h87.778v25H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
