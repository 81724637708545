import React, { useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import InputField from '../form-fields/InputField';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import createFocusOnErrorDecorator from 'final-form-focus';
import { catchFormError, clear } from '../../lib/forms';
import queryString from 'query-string';
import qs from 'query-string';
import rpc from '../../../common/rpc';
import { emailValidate } from '../../../common/validations';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ReCaptchaLegal } from '../ReCaptcha';
import WaitingButton from '../WaitingButton';
import { useSelector } from 'react-redux';

import './styles/Login.less';

const RPC = rpc('/api/v1/');
const focusOnError = createFocusOnErrorDecorator();

export default function Login() {
  const { t } = useTranslation();
  const entry_data = useSelector((state) => state.entry_data);
  const location = useLocation();
  const { app_code, app_token, redirect_to } = qs.parse(location.search);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const onSubmit = useCallback(
    async (values, form) => {
      const errors = {};

      if (!values.email) {
        errors.email = t('auth.err_no_email');
      } else if (!emailValidate(values.email)) {
        errors.email = t('validation.invalid_email');
      }
      if (!values.password) {
        errors.password = t('auth.err_no_password');
      }

      if (Object.keys(errors).length) return errors;

      try {
        const captcha_token = await executeRecaptcha('login');
        const mfa_token = await RPC('profile.login', {
          ...values,
          captcha: {
            action: 'login',
            token: captcha_token,
          },
          app: app_code
            ? {
                code: app_code,
                token: app_token,
              }
            : undefined,
        });
        if (mfa_token) {
          history.push({
            pathname: 'check',
            search: queryString.stringify({
              token: mfa_token,
              app_code,
              app_token,
            }),
          });
        } else if (redirect_to) {
          window.location = redirect_to;
        } else if (app_code) {
          window.location = `/settings/project/integrations/${app_code}`;
        } else {
          window.location = '/inbox';
        }
      } catch (e) {
        return catchFormError(e);
      }
    },
    [executeRecaptcha, app_code, app_token, redirect_to, history],
  );
  const renderForm = ({ handleSubmit, submitError, submitting, pristine, form }) => (
    <form onSubmit={handleSubmit}>
      <Field
        label={t('global.email')}
        name="email"
        autoFocus
        maxLength="100"
        component={InputField}
        type="text"
      />
      <Field
        label={t('global.password')}
        name="password"
        maxLength="100"
        component={InputField}
        type="password"
      />
      <Alert show={!!submitError} variant="danger">
        {submitError}
      </Alert>
      <div className="Login__footer">
        <Link to="/restore">{t('auth.login_forgot_password')}</Link>
        <WaitingButton type="submit" waiting={submitting} disabled={pristine}>
          {t('auth.login_btn')}
        </WaitingButton>
      </div>
      <ReCaptchaLegal />
    </form>
  );
  return (
    <>
      {/*<FBLoginButton type={FBLoginButtonType.LOGIN} />*/}
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...entry_data }}
        render={renderForm}
        mutators={{ clear }}
        decorators={[focusOnError]}
      />
    </>
  );
}
