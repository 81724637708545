import React from 'react';

export function InSalesSmallLogo(props) {
  return (
    <svg width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        fill="#C01F3C"
        d="M5.43 11.892a9.072 9.072 0 01.73-7.198C8.334.843 13.01-.798 17.406.369l1.28 2.003-2.044 1.661c-4.395-.83-8.536.49-10.347 3.689a7.111 7.111 0 00-.86 4.154"
      />
      <path
        fill="#C01F3C"
        d="M20.371 19.68c0 1.765-.663 3.091-1.99 3.98a8.723 8.723 0 01-4.984 1.333 10.575 10.575 0 01-7.062-2.795l2.493-2.468a8.602 8.602 0 004.76 1.861 5.217 5.217 0 002.467-.498 1.503 1.503 0 00.906-1.358 1.587 1.587 0 00-.831-1.425c-.582-.374-1.567-.65-2.958-.83-2.052-.245-3.558-.815-4.519-1.712a4.155 4.155 0 01-1.396-3.174 4.893 4.893 0 011.554-3.713c1.036-.983 2.53-1.483 4.482-1.5a9.529 9.529 0 016.127 2.206l-2.46 2.417a6.202 6.202 0 00-3.547-1.217c-1.026 0-1.757.15-2.18.445a1.333 1.333 0 00-.644 1.13 1.193 1.193 0 00.677 1.067 6.16 6.16 0 002.201.507c2.174.205 3.865.803 5.072 1.794a4.86 4.86 0 011.807 3.95"
      />
    </svg>
  );
}

export function InSalesLogo(props) {
  return (
    <svg width={75} height={25} viewBox="0 0 75 25" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path
          fill="#C01F3C"
          d="M17.032 11.915a8.805 8.805 0 01.71-6.988C19.851 1.19 24.392-.403 28.657.73L29.9 2.673l-1.984 1.613c-4.266-.806-8.286.476-10.044 3.58a6.903 6.903 0 00-.835 4.033"
        />
        <path
          fill="#4A4644"
          d="M3.339 7.758H0v16.58h3.339V7.759zM16.335 24.339h-3.29V17.08a2.017 2.017 0 00-.662-1.408 2.214 2.214 0 00-3.04-.024c-.399.356-.645.851-.69 1.383v7.307H5.314V12.274h3.34v.738a4.553 4.553 0 013.04-1.036 4.326 4.326 0 013.294 1.455 5.185 5.185 0 011.343 3.654l.004 7.254z"
        />
        <path
          fill="#C01F3C"
          d="M31.536 19.476c0 1.712-.643 3-1.931 3.863a8.468 8.468 0 01-4.839 1.294 10.266 10.266 0 01-6.855-2.714l2.42-2.395a8.35 8.35 0 004.62 1.806 5.065 5.065 0 002.396-.483 1.46 1.46 0 00.879-1.319 1.54 1.54 0 00-.807-1.383c-.564-.363-1.521-.632-2.87-.806-1.992-.237-3.455-.79-4.388-1.662a4.032 4.032 0 01-1.355-3.08 4.75 4.75 0 011.509-3.605c1.005-.954 2.455-1.44 4.35-1.456a9.25 9.25 0 015.948 2.141l-2.387 2.347a6.02 6.02 0 00-3.444-1.181c-.996 0-1.705.145-2.117.431a1.294 1.294 0 00-.625 1.097 1.158 1.158 0 00.658 1.036 5.98 5.98 0 002.137.492c2.11.199 3.751.78 4.923 1.742a4.717 4.717 0 011.754 3.835"
        />
        <path
          fill="#4A4644"
          d="M44.069 24.339h-3.17v-.404a8.952 8.952 0 01-3.226.565 4.84 4.84 0 01-3.334-1.113 3.694 3.694 0 01-1.295-2.911 3.956 3.956 0 011.432-3.137 5.295 5.295 0 013.605-1.246c.964-.002 1.926.105 2.867.318a1.138 1.138 0 00-.5-1.06 3.1 3.1 0 00-1.738-.432 7.043 7.043 0 00-3.391.887l-1.295-2.564a10.279 10.279 0 014.686-1.262c1.596 0 2.885.37 3.867 1.109.98.739 1.471 1.867 1.471 3.383l.02 7.867zm-3.17-3.307v-1.798a14.462 14.462 0 00-2.822-.299 2.198 2.198 0 00-1.335.404 1.084 1.084 0 00.1 1.923c.46.284.994.424 1.533.403a5.07 5.07 0 002.516-.64M49.484 24.339H46.14V9.189l3.343-1.653V24.34zM62.56 19.355h-7.98c.12.63.456 1.2.948 1.613.484.403 1.1.612 1.73.584a2.766 2.766 0 002.016-.863l2.218 1.726a5.16 5.16 0 01-4.242 2.145 5.546 5.546 0 01-4.242-1.762 6.395 6.395 0 01-1.641-4.528 7.016 7.016 0 01.75-3.226 5.294 5.294 0 012.117-2.27 5.674 5.674 0 012.867-.806c1.009 0 1.994.31 2.822.887a5.646 5.646 0 011.984 2.358c.449.979.674 2.357.674 4.134l-.02.008zm-3.286-2.738c-.1-.5-.39-.941-.806-1.234a2.508 2.508 0 00-1.396-.464 2.557 2.557 0 00-1.427.46 1.976 1.976 0 00-.806 1.234l4.435.004zM74.597 20.859a3.304 3.304 0 01-1.335 2.588c-.89.742-2.308 1.112-4.254 1.11a14 14 0 01-5.645-1.38l1.468-2.887a9.273 9.273 0 004.318 1.36c1.045-.033 1.674-.138 1.875-.311a.725.725 0 00.295-.529.702.702 0 00-.351-.528c-.226-.172-.92-.373-2.08-.605a10.503 10.503 0 01-3.509-1.258 2.719 2.719 0 01-1.411-2.318 3.544 3.544 0 011.355-2.976c.922-.766 2.148-1.148 3.677-1.145 1.863.034 3.692.507 5.339 1.379l-1.4 2.738a10.981 10.981 0 00-3.814-.988c-.47-.023-.941.05-1.383.213a.637.637 0 00-.435.59.652.652 0 00.435.459c.574.21 1.161.383 1.758.516 1.329.256 2.606.732 3.778 1.407a2.822 2.822 0 011.319 2.565z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H74.597V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
