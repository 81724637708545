import 'event-source-polyfill';
import { nanoid } from 'nanoid';
import { getAppHeaders } from '../../common/net-utils';

let instance = null;

export default class EventChannel {
  /**
   * @return {EventChannel}
   */
  static getInstance() {
    if (!instance) {
      instance = new EventChannel();
    }
    return instance;
  }

  static __test__cleanup() {
    instance = null;
  }

  constructor() {
    this._source = null;
    this._reconnectTimeout = 0;
    this._listeners = {};
    this._channel_id = window.__channel_id;

    this._handler = (e) => {
      let data = e.data && JSON.parse(e.data);
      this.emit(e.type, data && data.body);
    };
  }
  on(event, listener) {
    if (event && listener) {
      let needSetSubscription = false;
      if (!(event in this._listeners)) {
        needSetSubscription = true;
        this._listeners[event] = [];
      }
      this._listeners[event].push(listener);
      if (needSetSubscription) {
        this._setSubscription(event);
      }
    }
  }
  off(event, listener) {
    if (event && event in this._listeners) {
      if (listener == undefined) {
        this._listeners[event].length = 0;
      } else {
        this._listeners[event] = this._listeners[event].filter((f) => f != listener);
      }
      if (this._listeners[event].length === 0) {
        // С данном события сняли последнего подписчика - отпишемся от EventSource
        this._source.removeEventListener(event, this._handler, false);
        delete this._listeners[event];
      }
    } else if (event === undefined) {
      // Снимают всех подписчиков - отпишемся от всех событий на EventSource
      Object.keys(this._listeners).forEach((event) =>
        this._source.removeEventListener(event, this._handler, false),
      );
      this._listeners = {};
    }

    // Нет ни одного события с подписчиками - остановим подписку
    if (Object.keys(this._listeners).length === 0) {
      this._stopSource();
    }
  }
  emit(event, payload) {
    this._listeners[event].forEach((listener) => {
      try {
        listener(payload);
      } catch (e) {
        console.error(e);
      }
    });
  }
  _getURL() {
    const headers = getAppHeaders();
    const searchParams = new URLSearchParams();

    searchParams.append('q', this._channel_id || nanoid(5));
    Object.keys(headers).forEach((key) => {
      searchParams.append(key, headers[key]);
    });

    return `/channel/?${searchParams.toString()}`;
  }
  _getSource() {
    if (!this._source) {
      // Если стоит таймер реконнекта - отменим его
      if (this._reconnectTimeout) {
        clearTimeout(this._reconnectTimeout);
        this._reconnectTimeout = 0;
      }
      this._source = new EventSource(this._getURL());
      this._source.onerror = () => {
        // Произошла ошибка и канал закрылся
        if (this._source.readyState === EventSource.CLOSED) {
          // Зачистим источник. Если нас попросят подписку - сразу и создадим
          this._source = null;
          this._channel_id = null;
          // Запланируем реконнект
          this._reconnectTimeout = setTimeout(() => {
            this._getSource();
          }, 5000);
        }
      };

      // Если ранее были подписки - восстановим их все
      Object.keys(this._listeners).forEach((event) => this._setSubscription(event));
    }
    return this._source;
  }
  _setSubscription(event) {
    const source = this._getSource();
    source.addEventListener(event, this._handler, false);
  }
  _stopSource() {
    if (this._source) {
      this._source.close();
      this._source = null;
      this._channel_id = null;

      if (this._reconnectTimeout) {
        clearTimeout(this._reconnectTimeout);
      }
    }
  }
}
