import 'isomorphic-fetch';
import { getAppHeaders, getCSRFToken, getPid, NETError } from './net-utils';
import { nanoid } from 'nanoid';

// https://hackernoon.com/building-isomorphic-javascript-packages-1ba1c7e558c5
// Изоморфный вариант получения логгера и window.performance
const isNode = typeof window === 'undefined';
if (isNode) {
  global.__non_webpack_require__ = require;
}
const perf = !isNode ? window : __non_webpack_require__('perf_hooks');
const logger = !isNode
  ? console
  : __non_webpack_require__('@quickleyinc/utils/logger').getLogger('RPC');
const performance = perf.performance;

const rpc = (url) => {
  return async (method, params = {}) => {
    const begin = typeof performance !== 'undefined' && performance.now() | 0;
    let isError = false;
    let headers = {
      'X-Pid': getPid(),
      'X-CSRF-Token': getCSRFToken(),
      'X-Method': method,
      'X-Request-Id': nanoid(),
      ...getAppHeaders(),
    };
    let uri;
    let body;
    let error;
    if (typeof FormData !== 'undefined' && params instanceof FormData) {
      uri = `${url}${method}`;
      body = params;
    } else {
      uri = url;
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify({
        jsonrpc: '2.0',
        method: method || '',
        params: params || {},
        id: 3,
      });
    }
    let response;
    try {
      response = await fetch(uri, {
        credentials: 'same-origin',
        method: 'POST',
        body,
        headers,
      });
      const content_type = response.headers.get('content-type') || '';
      if (content_type.startsWith('application/json')) {
        const json = await response.json();
        body = json.result;
        error = json.error;
      }
      if (response.status >= 200 && response.status < 300) {
        return body;
      } else {
        throw new NETError(body || error, response.status);
      }
    } catch (e) {
      isError = true;
      throw e;
    } finally {
      const responseCode = (response && response.status) || '---';
      const end = typeof performance !== 'undefined' && performance.now() | 0;
      const duration = (end - begin) >> 0;
      const text = `[rpc] [${
        response?.headers?.get('X-Request-Id') || ''
      }] ${responseCode}, ${duration} ms, ${method} ${JSON.stringify(params)}`;
      if (isError) {
        logger.error(text);
      } else {
        logger.debug(text);
      }
    }
  };
};

if (typeof window !== 'undefined') {
  window.__rpc_v1 = rpc('/api/v1/');
}

export default rpc;
