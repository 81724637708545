import React from 'react';
import * as Sentry from '@sentry/browser';
import '../styles/ErrorBoundary.less';

/**
 * TODO ждем переводов и внедрим в app-boot.js
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      has_error: false,
    };
  }
  static getDerivedStateFromError(error) {
    return {
      has_error: true,
    };
  }
  componentDidCatch(error, error_info) {
    Sentry.withScope((scope) => {
      scope.setExtras(error_info);
      const event_id = Sentry.captureException(error);
      this.setState({ event_id });
    });
  }
  render() {
    if (this.state.has_error) {
      const { FallbackComponent } = this.props;
      if (FallbackComponent) {
        return <FallbackComponent />;
      } else {
        return (
          <div className="ErrorBoundary">
            <div className="ErrorBoundary__content">
              <h1>Whoops!</h1>
              <h2>Something went wrong</h2>
            </div>
          </div>
        );
      }
    }

    return this.props.children;
  }
}
