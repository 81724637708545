import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarElement from '../../Sidebar/Element';
import './SidebarElement.less';
import { Quickley } from '../../../../common/types';

function Addresses({ shipping_address }) {
  const { t } = useTranslation();
  if (!shipping_address?.length) return null;
  return (
    <>
      <SidebarElement.Title>
        {t('integration.ecwid.sidebar_element.shipping_address')}
      </SidebarElement.Title>
      {shipping_address.map((sa, i) => {
        const value = [sa.postal_code, sa.street, sa.city, sa.country_name].join(', ');
        return <div key={i}>{value}</div>;
      })}
    </>
  );
}

function Cart({ cart, total }) {
  const { t } = useTranslation();
  if (!cart?.length) return null;
  return (
    <>
      <SidebarElement.Title>{t('integration.ecwid.sidebar_element.cart')}</SidebarElement.Title>
      {cart.map((item) => {
        return (
          <div className="EcwidCart__item">
            <div>
              <a href={item.product.url} target="_blank">
                {item.product.name}
              </a>
              {item.options &&
                Object.keys(item.options).map((key, i) => {
                  return (
                    <div key={i}>
                      {key}: {item.options[key]}
                    </div>
                  );
                })}
            </div>
            <div className="EcwidCart__price">
              {item.product.price} <span>x</span> {item.quantity}
            </div>
          </div>
        );
      })}
      {total ? (
        <div className="EcwidCart__total">
          <b>{t('integration.ecwid.sidebar_element.total')}</b>: {total}
        </div>
      ) : null}
    </>
  );
}

export default function EcwidSidebarElement(props) {
  const { t } = useTranslation();
  const { person } = props;
  const qchat_contact = person.contacts.find((contact) => {
    return contact.type === Quickley.PersonContactType.qchat;
  });
  const ecwid = qchat_contact?.meta?.ecwid;

  if (ecwid && (ecwid.shipping_address?.length || ecwid.cart?.length)) {
    return (
      <SidebarElement>
        <SidebarElement.Header>{t('integration.ecwid.full_name')}</SidebarElement.Header>
        <SidebarElement.Body>
          <Addresses {...ecwid} />
          <Cart {...ecwid} />
        </SidebarElement.Body>
      </SidebarElement>
    );
  } else {
    return null;
  }
}
