import React, { useCallback } from 'react';
import classNames from 'classnames';
import './styles/ListItem.less';

export default function ListItem(props) {
  const { selected, onSelect, ...item } = props;
  const class_name = classNames('DropdownListItem', {
    DropdownListItem_selected: item.value === (selected && selected.value),
    DropdownListItem_disabled: item.disabled,
  });
  const onSelectEvent = useCallback((event) => onSelect(event, item), [onSelect]);

  return (
    <div key={item.value} className={class_name} onClick={onSelectEvent}>
      {item.label}
    </div>
  );
}
