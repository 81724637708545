import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import createFocusOnErrorDecorator from 'final-form-focus';
import InputField from '../form-fields/InputField';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import rpc from '../../../common/rpc';
import { FORM_ERROR } from 'final-form';
import { catchFormError } from '../../lib/forms';
import './styles/UpdatePassword.less';

const RPC = rpc('/api/v1/');
const focusOnError = createFocusOnErrorDecorator();

const UpdatePassword = (props) => {
  const { t } = useTranslation();
  const onSubmit = async (values) => {
    const errors = {};

    if (!values.new_password) {
      errors.new_password = t('auth.err_no_new_password');
    } else if (!values.new_password_confirmation) {
      errors.new_password_confirmation = t('auth.err_no_new_password_confirmation');
    } else if (values.new_password != values.new_password_confirmation) {
      errors[FORM_ERROR] = t('auth.err_passwords_no_match');
    }

    if (Object.keys(errors).length) return errors;

    try {
      await RPC('profile.updatePassword', {
        password: values.new_password,
        token: props.match.params.token,
      });
      window.location = '/login';
    } catch (e) {
      return catchFormError(e);
    }
  };
  const renderForm = ({ handleSubmit, submitError, submitting, pristine }) => (
    <form onSubmit={handleSubmit}>
      <Field
        label={t('settings.new_password')}
        name="new_password"
        maxLength="100"
        autoFocus
        component={InputField}
        type="password"
      />
      <Field
        label={t('settings.new_password_confirmation')}
        name="new_password_confirmation"
        maxLength="100"
        component={InputField}
        type="password"
      />
      <Alert show={!!submitError} variant="danger">
        {submitError}
      </Alert>
      <div className="UpdatePassword__footer">
        <Button type="submit" disabled={submitting || pristine}>
          {t('auth.update_password_btn')}
        </Button>
      </div>
    </form>
  );
  return <Form onSubmit={onSubmit} render={renderForm} decorators={[focusOnError]} />;
};

export default UpdatePassword;
