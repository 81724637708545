import React from 'react';
import classNames from 'classnames';
import ErrorBoundary from '../../ErrorBoundary';
import './Element.less';

export default function Element({ children, className }) {
  return (
    <ErrorBoundary>
      <div className={classNames('SidebarElement', className)}>{children}</div>
    </ErrorBoundary>
  );
}

Element.Header = function ({ children }) {
  return <div className="SidebarElement__header">{children}</div>;
};

Element.Body = function ({ children, className }) {
  return <div className={classNames('SidebarElement__body', className)}>{children}</div>;
};

Element.Title = function ({ children }) {
  return <div className="SidebarElement__title">{children}</div>;
};

Element.Separator = function () {
  return <div className="SidebarElement__separator" />;
};
