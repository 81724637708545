import cookie from 'js-cookie';

let store = null;

/**
 * Ошибка при работе с сетью
 */
export class NETError extends Error {
  constructor(xhr_error, status_code) {
    super();
    this.message = (xhr_error && xhr_error.message) || '';
    this.details = (xhr_error && xhr_error.details) || {};
    this.code = xhr_error.code;
    this.status_code = status_code;
    if (store) {
      store.dispatch(netErrorAction(this.status_code, this.code));
    }
  }
}

/**
 * Получить ид профиля
 * @returns {string|String}
 */
export function getPid() {
  return cookie.get('pid');
}

/**
 * Получить CSRF_TOKEN
 * @returns {string|String}
 */
export function getCSRFToken() {
  return cookie.get('csrf-token');
}

export function syncNetWithStore(newStore) {
  store = newStore;
}

export const NET_ERROR = 'NET_ERROR';

function netErrorAction(status_code, code) {
  return {
    type: NET_ERROR,
    status_code,
    code,
  };
}

/**
 * Additional headers for the rpc call
 */
let app_headers = {};

/**
 * Appends header that will be added to all rpc requests
 * @param header
 * @param value
 */
export function addAppHeader(header, value) {
  app_headers[header] = value;
}

/**
 * Removes header from the list of additional headers
 * @param header
 */
export function removeAppHeader(header) {
  delete app_headers[header];
}

/**
 * Returns all additional headers
 */
export function getAppHeaders() {
  return app_headers;
}
