import {useTranslation} from 'react-i18next';
import qs from 'query-string';
import {useLocation} from 'react-router-dom';
import React, {FormEventHandler, ReactElement, useCallback} from 'react';
import {Field, Form} from 'react-final-form';
import Alert from 'react-bootstrap/Alert';
import sendEvent from '@client/lib/tracker';
import WaitingButton from '../WaitingButton';
import {catchFormError, clear} from '../../lib/forms';
import InputField from '../form-fields/InputField';
import rpc from '../../../common/rpc';

const RPC = rpc('/api/v1/');

function CompleteSignUpForm(): ReactElement {
  const {t} = useTranslation();
  const {search} = useLocation();
  const {key} = qs.parse(search);

  const onSubmit = useCallback(
    async (values: {workspace_display_name: string}): Promise<Record<string, string>> => {
      if (!values.workspace_display_name) {
        return {
          workspace_display_name: t('auth.err_no_workspace_display_name')
        };
      }

      try {
        await RPC('profile.completeSignUp', {
          ...values,
          key
        });
        sendEvent('Sign up');
        // tslint:disable-next-line:ban-ts-ignore
        // @ts-ignore
        window.location = '/';
      } catch (e) {
        return catchFormError(e);
      }
      return undefined;
    },
    [t, key]
  );

  const renderForm = ({
    handleSubmit,
    submitError,
    submitting,
    pristine
  }: {
    handleSubmit: FormEventHandler;
    submitError: string;
    submitting: boolean;
    pristine: boolean;
  }): ReactElement => (
      <form onSubmit={handleSubmit}>
          <Field
        name="workspace_display_name"
        maxLength="100"
        label={t('auth.company_name')}
        component={InputField}
        type="text"
      />
          <Alert show={!!submitError} variant="danger">
              {submitError}
          </Alert>
          <div className="SignUp__footer">
              <WaitingButton type="submit" waiting={submitting} disabled={pristine}>
                  {t('auth.signup_btn')}
              </WaitingButton>
          </div>
      </form>
  );

  return <Form onSubmit={onSubmit} render={renderForm} mutators={{clear}} />;
}

export default function CompleteSignUp(): ReactElement {
  const {t} = useTranslation();

  return (
      <div className="SignUp">
          <h1>{t('auth.complete_signup_title')}</h1>
          <CompleteSignUpForm />
      </div>
  );
}
