import i18n from './i18n';
import moment from 'moment';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

function sameDate(d1, d2) {
  d1 = new Date(d1);
  d2 = new Date(d2);
  return (
    d1.getDate() == d2.getDate() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getFullYear() == d2.getFullYear()
  );
}

/**
 * Возвращает пару значений - i18n-ключ для строки форматирования даты и
 * i18n-ключ для строки, в которую надо подставить результат форматирования даты
 * @param {Number} ts Дата, которую будем форматировать
 * @param {Number} now Текущая дата с которой будем сравнивать переданную в первом аргументе
 * @param {Number} [use_days] Использовать дни для отображения относительного времени
 * @returns {string[]}
 */
function getFormatString(ts, now, use_days) {
  now = now || Date.now();
  let delta = now - ts;
  let same = sameDate(ts, now);

  switch (true) {
    case delta < 30 * SECOND:
      return ['timeago.format.none', 'timeago.just_now'];
    case delta < MINUTE:
      return ['timeago.format.seconds', 'timeago.seconds'];
    case delta < HOUR:
      return ['timeago.format.minutes', 'timeago.minutes'];
    case delta < 3 * HOUR:
      return ['timeago.format.hours', 'timeago.hours'];
    case delta < 20 * HOUR:
      if (!same) {
        return ['timeago.format.time', 'timeago.yesterday'];
      } else {
        return ['timeago.format.hours', 'timeago.hours'];
      }
    case use_days:
      return ['timeago.format.days', 'timeago.days'];
    case delta < DAY:
    default:
      return ['timeago.format.fulldate', 'timeago.raw'];
  }
}

/**
 *
 * @param {number} ts время в unixtime c миллисекундами
 * @param {number} now
 * @param {number} [use_days]
 * @returns {string}
 */
export function getTimeAgoString(ts, now, use_days) {
  let [format, str] = getFormatString(ts, now, use_days) || ['', ''];
  let delta = new Date(now - ts);
  if (format) {
    format = i18n.t(format);
    let count = +moment(delta).utc().format(format);
    if (format === 'DDD') count--;
    return i18n.t(str, {
      count,
      absolute: moment(new Date(ts)).format(format),
    });
  } else {
    return i18n.t(str);
  }
}

/**
 * @param {number} ts время в unixtime c миллисекундами
 * @param now
 * @returns {string}
 */
export function getDateString(ts, now) {
  if (typeof now === 'number') {
    now = new Date(now);
  }
  let tsDate = new Date(ts);
  let format;
  if (now.getFullYear() != tsDate.getFullYear()) {
    format = 'timeago.format.otheryeardate';
  } else {
    format = 'timeago.format.thisyeardate';
  }
  return moment(tsDate).format(i18n.t(format));
}

/**
 *
 * @param {number} ts время в unixtime c миллисекундами
 * @returns {string}
 */
export function getTimeString(ts) {
  let tsDate = new Date(ts);
  return moment(tsDate).format(i18n.t('timeago.format.time'));
}
