let moment = require('moment');
require('moment/locale/ru');

module.exports = {
  updateMomentLocale: function () {
    moment.updateLocale('ru', {
      monthsShort: {
        format: 'янв_февр_мар_апр_мая_июня_июля_авг_сент_окт_нояб_дек'.split('_'),
        standalone: 'янв_февр_март_апр_май_июнь_июль_авг_сент_окт_нояб_дек'.split('_'),
      },
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY, HH:mm',
        LLLL: 'dddd, D MMMM YYYY, HH:mm',
      },
    });
  },
};
