import React from 'react';
import Form from 'react-bootstrap/Form';
import Checkbox from './Checkbox';
import './styles/CheckboxGroupField.less';

export default function CheckboxGroupField(props) {
  const { fields, label, items, meta, ignoretouchstate } = props;
  const error =
    (meta.touched || ignoretouchstate) &&
    !meta.dirtySinceLastSubmit &&
    !meta.submitting &&
    (meta.error || meta.submitError);
  const toggle = (checked, item) => {
    if (checked) fields.push(item);
    else fields.remove(item);
  };
  return (
    <Form.Group>
      <Form.Label className="CheckboxGroupField__label">{label}</Form.Label>
      <div className={meta.invalid ? 'is-invalid' : ''}>
        {items.map((item) => (
          <div key={item.label}>
            <Checkbox {...item} onChange={(checked) => toggle(checked, item)} />
          </div>
        ))}
      </div>
      {error ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : null}
    </Form.Group>
  );
}
