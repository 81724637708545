import React, {ReactElement} from 'react';

export enum Currency {
  RUB = 'rub',
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp'
}

export const CurrencySymbol = {
  [Currency.RUB]: '₽',
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£'
};

interface ICurrencyFormatProps {
  value: number;
  currency: Currency;
}

export default function CurrencyFormat(props: ICurrencyFormatProps): ReactElement {
  const {currency, value} = props;
  switch (currency) {
    // Backward symbol position
    case Currency.EUR:
    case Currency.RUB:
      return (
          <span className="CurrencyFormat">
              {value}
              <span className="CurrencyFormat__sign">
            &nbsp;
                  {CurrencySymbol[currency]}
              </span>
          </span>
      );
    // Forward symbol position
    case Currency.USD:
    case Currency.GBP:
      return (
          <span className="CurrencyFormat">
              {value < 0 ? '-' : null}
              <span className="CurrencyFormat__sign">{CurrencySymbol[currency]}</span>
              {Math.abs(value)}
          </span>
      );
    default:
      return (
          <span className="CurrencyFormat">
              {value}
              <span className="CurrencyFormat__sign">
            &nbsp;
                  {currency}
              </span>
          </span>
      );
  }
}
