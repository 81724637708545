import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import Promise from 'bluebird';
import moment from 'moment';
import 'moment-timezone/moment-timezone';
import i18n, { addLocaleData } from '../lib/i18n';
import { I18nextProvider } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { TimeAgoProvider } from '../components/TimeAgo';
import { syncNetWithStore } from '../../common/net-utils';
import { Router } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { loadTrackers } from './tracker';

const stripe = loadStripe(window.stripe_pk);

Promise.config({
  cancellation: true,
});

export default function (reducer, middlewares, App, syncWithEventStream = () => {}) {
  loadTrackers();
  const initial_state = window.__initialState__ || {};
  initial_state.module_name = window.__module_name__;
  initial_state.country_code = window.__country_code__ || 'US';
  const locale_info = window.__i18n__ || {};
  const lng = (initial_state.lng = window.__lng__ || 'en');
  const history = createBrowserHistory();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    combineReducers(reducer),
    initial_state,
    composeEnhancers(applyMiddleware(thunk, ...middlewares)),
  );
  const profile = initial_state.profile;

  if (profile) {
    if (profile.time_zone !== null) {
      moment.tz.add(`Quickley/Local|PLZ|${(-profile.time_zone).toString(25)}0|0|`);
      moment.tz.setDefault('Quickley/Local');
    }
  }

  window.__store__ = store; //debug

  syncNetWithStore(store);
  syncWithEventStream(store);

  addLocaleData(lng, locale_info).then(() => {
    ReactDOM.render(
      <Elements stripe={stripe}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <TimeAgoProvider>
              <Router history={history}>
                <div className="full-height">
                  <App />
                </div>
              </Router>
            </TimeAgoProvider>
          </Provider>
        </I18nextProvider>
      </Elements>,
      document.getElementById('root'),
    );
  });
}
