import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Quickley} from '@common/types';
import SidebarElement from '../../../Sidebar/Element';
import {ISidebarElementProps} from '../../types';
import {ICartItem, ICartItemProductOption, ICartProps} from '../types';
import CurrencyFormat, {Currency} from '../../../CurrencyFormat';
import {ShopifySmallLogo} from '../ShopifyLogo';
import './SidebarElement.less';

function Cart(props: ICartProps): ReactElement {
  const {t} = useTranslation();
  const {items, currency, total} = props;
  if (items?.length) {
    return (
        <>
            <SidebarElement.Title>{t('integration.shopify.sidebar_element.cart')}</SidebarElement.Title>
            {items.map((item: ICartItem) => (
                <div className="ShopifyCart__item" key={item.product.url}>
                    <div>
                        <a href={item.product.url} target="_blank" rel="noreferrer">
                            {item.product.name}
                        </a>
                        {item.options?.map((option: ICartItemProductOption) => (
                            <div key={option.name}>
                                {option.name}: {option.value}
                            </div>
              ))}
                    </div>
                    <div className="ShopifyCart__price">
                        <CurrencyFormat value={item.product.price} currency={Currency[currency]} />{' '}
                        <span>x</span> {item.quantity}
                    </div>
                </div>
        ))}
            {total ? (
                <div className="ShopifyCart__total">
                    <b>{t('integration.shopify.sidebar_element.total')}</b>
                    :
                    <CurrencyFormat value={total} currency={Currency[currency]} />
                </div>
        ) : null}
        </>
    );
  }
  return (
      <SidebarElement.Title>
          {t('integration.shopify.sidebar_element.cart_is_empty')}
      </SidebarElement.Title>
  );
}

export default function ShopifySidebarElement(props: ISidebarElementProps): ReactElement {
  const {t} = useTranslation();
  const {person} = props;
  const qchat_contact = person.contacts.find(
    (contact: Quickley.PersonContact) => contact.type === Quickley.PersonContactType.qchat
  );
  const shopify = qchat_contact?.meta?.shopify;

  if (shopify && shopify.cart) {
    return (
        <SidebarElement>
            <SidebarElement.Header>
                <div className="ShopifySidebarElement__header">
                    <ShopifySmallLogo height={20} width={20} />
                    <span className="ShopifySidebarElement__name">
                        {t('integration.shopify.full_name')}
                    </span>
                </div>
            </SidebarElement.Header>
            <SidebarElement.Body>
                <Cart {...shopify.cart} />
            </SidebarElement.Body>
        </SidebarElement>
    );
  }
  return null;
}
